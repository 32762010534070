/* eslint-disable */
export default {
  key: 'yds',
  name: 'YDS',
  description: 'Yosemite Decimal System',
  types: ['route'],
  data: [
    { value: 2,    name: '5.3', level: 0 },
    { value: 3,    name: '5.4', level: 0, name0: '5.4/5' },
    { value: 4,    name: '5.5', level: 1 },
    { value: 4.33, name: '5.6', level: 0, name0: '5.6/7' },
    { value: 4.67, name: '5.7', level: 1 },
    { value: 5,    name: '5.8', level: 0, name0: '5.8/9' },
    { value: 5.33, name: '5.9', level: 1 },
    { value: 5.67, name: '5.10a', level: 0, name0: '5.10', name1: '5.10a/b' },
    { value: 6,    name: '5.10b', level: 2 },
    { value: 6.17, name: '5.10c', level: 1, name1: '5.10c/d' },
    { value: 6.33, name: '5.10d', level: 2 },
    { value: 6.5,  name: '5.11a', level: 0, name0: '5.11', name1: '5.11a/b' },
    { value: 6.67, name: '5.11b', level: 2 },
    { value: 6.83, name: '5.11c', level: 1, name1: '5.11c/d' },
    { value: 7,    name: '5.11d', level: 2 },
    { value: 7.17, name: '5.12a', level: 0, name0: '5.12', name1: '5.12a/b' },
    { value: 7.33, name: '5.12b', level: 2 },
    { value: 7.5,  name: '5.12c', level: 1, name1: '5.12c/d' },
    { value: 7.67, name: '5.12d', level: 2 },
    { value: 7.83, name: '5.13a', level: 0, name0: '5.13', name1: '5.13a/b' },
    { value: 8,    name: '5.13b', level: 2 },
    { value: 8.17, name: '5.13c', level: 1, name1: '5.13c/d' },
    { value: 8.33, name: '5.13d', level: 2 },
    { value: 8.5,  name: '5.14a', level: 0, name0: '5.14', name1: '5.14a/b' },
    { value: 8.67, name: '5.14b', level: 2 },
    { value: 8.83, name: '5.14c', level: 1, name1: '5.14c/d' },
    { value: 9,    name: '5.14d', level: 2 },
    { value: 9.17, name: '5.15a', level: 0, name0: '5.15', name1: '5.15a/b' },
    { value: 9.33, name: '5.15b', level: 2 },
    { value: 9.5,  name: '5.15c', level: 1 },
  ],
}
