export default [
  {
    path: 'stats',
    name: 'gym.stats',
    redirect: { name: 'gym.stats.climbs' },
  },
  {
    path: 'stats/:climbsType(routes|boulders|climbs)?',
    name: 'gym.stats.climbs',
    meta: { requiresAuth: 'isAdminSetter' },
    component: () => import(/* webpackChunkName: 'tl-admin-stats' */ './climbs/tl-stats-climbs.vue'),
  },
  {
    path: 'stats/popularity',
    name: 'gym.stats.popularity',
    meta: { requiresAuth: 'isAdminSetter' },
    component: () => import(/* webpackChunkName: 'tl-admin-stats' */ './popularity/tl-stats-popularity.vue'),
  },
  {
    path: 'stats/setters',
    name: 'gym.stats.setters',
    meta: { requiresAuth: 'isAdminSetter' },
    component: () => import(/* webpackChunkName: 'tl-admin-stats' */ './setters/tl-stats-setters.vue'),
  },
  {
    path: 'stats/usage',
    name: 'gym.stats.usage',
    meta: { requiresAuth: 'isAdminSetter' },
    component: () => import(/* webpackChunkName: 'tl-admin-stats' */ './usage/tl-stats-usage.vue'),
  },
]
