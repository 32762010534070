import vuetify from '@/plugins/vuetify'

const state = () => ({
  expanded: false,
  mini: false,
})

const getters = {
  isExpanded(state) {
    return state.expanded && !state.mini
  },
}

const mutations = {
  toggle(state, expand) {
    let toggle = expand == undefined
    let wasCollapsed = !state.expanded
    if (vuetify.framework.breakpoint.lgAndUp) {
      state.expanded = true
      state.mini = toggle ? !state.mini : !expand
      if (wasCollapsed) state.mini = true
    } else {
      state.mini = false
      state.expanded = toggle ? !state.expanded : expand
    }
  },
}

const actions = {
  expand({ commit }) {
    commit('toggle', true)
  },
  collapse({ commit }) {
    commit('toggle', false)
  },
  toggle({ commit }, expand) {
    commit('toggle', expand)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
