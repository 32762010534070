import Model from '@/services/orm/Model'

export default class RegionWall extends Model {
  static name = 'region_walls'
  static urlParent = 'region'

  static relations = [
    RegionWall.belongsTo('regions', { key: 'region_id', field: 'region' }),
    RegionWall.belongsTo('walls', { key: 'wall_id', field: 'wall' }),
  ]

  // static parsers = {
  //   id: this.increment(),
  //   region_id: this.attr(null),
  //   wall_id: this.attr(null),
  // }
}
