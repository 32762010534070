import Vue from 'vue'
import Container from './Container'
import { HasMany, HasOne, BelongsTo } from './Relation'

export default class Model {
  static primaryKey = 'id'
  static relations = []
  static indexes = []
  static schema = []

  constructor(record) {
    const relationFields = this.constructor.relations.map(rel => rel.field)
    for (const prop in record) {
      if (relationFields.includes(prop)) return // Skip relation fields: they are defined as getters.
      this[prop] = this.$parseProp(prop, record[prop])
    }
  }

  toJSON() {
    let copy = { ...this } // Clone to prevent modifying the original
    this.constructor.relations.forEach(r => delete copy[r.field]) // Prevent serializing relation fields
    return copy
  }

  $updateRelations() {
    this.constructor.relations.forEach(rel => {
      if (!rel.relatedModel) return // Omit not-registered resources
      // 0. Save current relations pointing to back this instance:
      let relatedInstances = [].concat(this[rel.field] || [])
      // 1. Update own relations:
      Vue.set(this, rel.field, rel.getGetter().call(this))
      // 2. Update old and new relations pointing to back this instance:
      let inverseRel = rel.getInverseRel()
      if (!inverseRel) return
      relatedInstances.concat(this[rel.field] || []).forEach(relatedInstance => {
        Vue.set(relatedInstance, inverseRel.field, inverseRel.getGetter().call(relatedInstance))
      })
    })
  }

  $parseProp(prop, value) {
    const parser = this.constructor.parsers[prop]
    return parser ? parser(value) : value
  }

  get $id() {
    return this[this.constructor.primaryKey]
  }
  set $id(newVal) {
    return (this[this.constructor.primaryKey] = newVal)
  }

  $update(changes) {
    return this.constructor.dispatch('update', { instance: this, changes })
  }

  $changeLog({ since } = {}) {
    return this.constructor.getters('changes/changeLog')({ id: this.$id, since })
  }

  $changes({ since } = {}) {
    return this.constructor.getters('changes/changes')({ id: this.$id, since })
  }

  $changed({ since } = {}) {
    return this.constructor.getters('changes/changed')({ id: this.$id, since })
  }

  $isNew() {
    return this.constructor.getters('isNew')(this)
  }

  $restore({ backTo } = {}) {
    return this.constructor.dispatch('restore', { instance: this, backTo })
  }

  $purgeChanges({ since, until } = {}) {
    return this.constructor.dispatch('purgeChanges', { instance: this, since, until })
  }

  $eject() {
    return this.constructor.dispatch('eject', this)
  }

  static parsers = {}

  static hasMany(relatedModelName, { key, field }) {
    return new HasMany({ ownerModel: this, relatedModelName, localKey: key, field })
  }

  static hasOne(relatedModelName, { key, field }) {
    return new HasOne({ ownerModel: this, relatedModelName, localKey: key, field })
  }

  static belongsTo(relatedModelName, { key, field }) {
    return new BelongsTo({ ownerModel: this, relatedModelName, foreignKey: key, field })
  }

  static database() {
    return Container.database
  }

  static store() {
    return this.database().store
  }

  static namespace() {
    return this.database().namespace + '/' + this.name
  }

  static getters(method) {
    return this.store().getters[this.namespace() + '/' + method]
  }

  static dispatch(method, payload) {
    return this.store().dispatch(this.namespace() + '/' + method, payload)
  }

  static all() {
    return this.getters('all')
  }

  static find(id) {
    return this.getters('find')(id)
  }

  static findBy(index, id) {
    return this.getters('findBy')(index, id)
  }

  static findAllBy(index, id) {
    return this.getters('findAllBy')(index, id)
  }

  static inject(data) {
    return this.dispatch('inject', { data })
  }

  static ejectAll() {
    return this.dispatch('ejectAll')
  }
}
