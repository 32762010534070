import systemsPredefined from './systems'
import { round } from '@/services/utils'
import store from '@/store'

export default class Grade {
  constructor(value, climbType, systemCustom) {
    let fallbackType = store.state.climbType || 'boulder'
    this.value = value || 2.0
    this.climbType = ['boulder', 'route'].includes(climbType) ? climbType : fallbackType
    this.systemCustom = systemCustom // An option to create a grade in a different system.
  }

  static updateCustomSystems(gym) {
    ;['boulder', 'route'].forEach(type => {
      let customSystem = gym && gym[`grading_system_custom_${type}s_json`]
      if (customSystem) {
        let system = JSON.parse(customSystem)
        system.data = [...system.data].sort((a, b) => a.value - b.value) // Assure correct grades order
        Grade.systems[`custom_${type}`] = system
      } else {
        delete Grade.systems[`custom_${type}`]
      }
    })
  }

  // Change the global Grade system for boulders or routes
  static setSystem(type, systemKey) {
    if (!Grade.systems[systemKey]) return false
    return (Grade[`system_${type}`] = Grade.systems[systemKey])
  }

  static system(type) {
    type = type || store.state.climbType || 'boulder'
    return Grade[`system_${type}`]
  }
  static systemGrades(type) {
    return Grade.system(type).data.map(d => new Grade(d.value, type))
  }

  get system() {
    return this.systemCustom || Grade[`system_${this.climbType}`]
  }

  // Rounds value to closest in grading sytem and returns corresponding grade item index.
  get idxRound() {
    let roundVal = round(this.value, 2)
    return this.system.data.findIndex((item, index) => {
      let thisValue = item.value
      let nextItem = this.system.data[index + 1]
      let nextValue = nextItem ? nextItem.value : 100
      let midwayValue = thisValue + (nextValue - thisValue) / 2
      return roundVal <= midwayValue
    })
  }
  get idxFloor() {
    return this.idxCeil - 1
  }
  get idxCeil() {
    let roundVal = round(this.value, 2)
    let idx = this.system.data.findIndex((item, i) => item.value > roundVal || i == this.system.data.length - 1)
    return idx < 1 ? 1 : idx
  }

  // System grades
  get roundedGrade() {
    return this.system.data[this.idxRound]
  }
  get floorGrade() {
    return this.system.data[this.idxFloor]
  }
  get ceilGrade() {
    return this.system.data[this.idxCeil]
  }

  // Steps
  get previous() {
    let prevGrade = this.system.data[this.roundIdx - 1]
    let value = prevGrade ? prevGrade.val : this.value
    return new Grade(value, this.climbType)
  }
  get next() {
    let nextGrade = this.system.data[this.roundIdx + 1]
    let value = nextGrade ? nextGrade.val : this.value
    return new Grade(value, this.climbType)
  }

  // Names
  get name() {
    return this.roundedGrade.name
  }
  get nameOrQ() {
    let index = this.idxRound
    if (index == 0) return '?'
    return this.system.data[index].name
  }
  get nameFloor() {
    return this.floorGrade.name
  }
  get nameFloorOrQ() {
    let index = this.idxFloor
    if (index == 0) return '?'
    return this.system.data[index].name
  }
  get nameCeil() {
    return this.ceilGrade.name
  }
  // Strips the '5.' from the YDS names so they fit in the grade-circles
  get nameStripped() {
    return this.name.replace(/^(5\.)(?=.{3,})/, '')
  }
  get nameOrQStripped() {
    return this.nameOrQ.replace(/^(5\.)(?=.{3,})/, '')
  }

  // Values
  get valueRounded() {
    return this.roundedGrade.value
  }
  get valueFloor() {
    return this.floorGrade.value
  }
  get valueCeil() {
    return this.ceilGrade.value
  }

  // Points
  get points() {
    return round(this.value * 100)
  }
  get pointsRounded() {
    return this.valueRounded * 100
  }
  get pointsFloor() {
    return this.valueFloor * 100
  }
  get pointsCeil() {
    return this.valueCeil * 100
  }

  // Percentages
  get subGradePercent() {
    let next = this.valueCeil
    let base = this.valueFloor
    let roundVal = round(this.value, 2)
    let percent = ((roundVal - base) / (next - base)) * 100
    if (percent < 0) return 0
    if (percent > 100) return 100
    return round(percent) || 0 // Prevent NaNs
  }
  get percentToNextGrade() {
    return 100 - this.subGradePercent
  }
}

Grade.systems = Object.assign({}, systemsPredefined)
Grade.system_boulder = systemsPredefined['french_boulder']
Grade.system_route = systemsPredefined['french']
