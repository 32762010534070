export default [
  {
    path: 'login',
    name: 'auth.login',
    component: () => import(/* webpackChunkName: 'tl-auth' */ './tl-login.vue'),
  },
  {
    path: 'logout',
    name: 'auth.logout',
    component: () => import(/* webpackChunkName: 'tl-auth' */ './tl-logout.vue'),
  },
  {
    path: 'reset-password-request',
    name: 'auth.resetPwdReq',
    component: () => import(/* webpackChunkName: 'tl-auth' */ './tl-reset-pwd-req.vue'),
  },
  {
    path: 'reset-password',
    name: 'auth.resetPwd',
    component: () => import(/* webpackChunkName: 'tl-auth' */ './tl-reset-pwd.vue'),
  },
]
