<template>
  <v-banner v-if="banner" :single-line="$vuetify.breakpoint.smAndUp" sticky app>
    <v-icon slot="icon" color="warning" size="36">{{ banner.icon }}</v-icon>
    {{ banner.text }}
    <template v-slot:actions>
      <v-btn
        v-for="btn in banner.btns"
        :key="btn.text"
        :icon="!!btn.icon"
        :loading="btn.loading"
        :disabled="btn.disabled"
        :color="btn.color"
        :text="!!btn.text"
        @click="btn.action($event)"
      >
        <v-icon size="24">{{ btn.icon }}</v-icon>
        {{ btn.text }}
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('toolbar', ['banners']),
    banner() {
      return this.banners.slice(-1)[0]
    },
  },
}
</script>
