const state = () => ({
  expanded: false,
  component: null,
})

const mutations = {
  setComponent(state, newComponent) {
    state.component = newComponent
  },
  clearComponent(state) {
    state.component = null
    state.expanded = false
  },
  toggle(state, expand) {
    if (!state.component) {
      state.expanded = false
    } else {
      state.expanded = expand == undefined ? !state.expanded : !!expand
    }
  },
}

const actions = {
  clear({ commit }) {
    commit('clearComponent')
    commit('toggle', false)
  },
  expand({ commit }) {
    commit('toggle', true)
  },
  collapse({ commit }) {
    commit('toggle', false)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
