import Model from '@/services/orm/Model'
import { parseFloatSafe } from '@/services/parsers'
import { arrayMin, arrayMax } from '@/services/utils'
import store from '@/store'

export default class Climb extends Model {
  static name = 'climbs'
  static urlParent = 'gym'

  static relations = [
    Climb.belongsTo('gyms', { key: 'gym_id', field: 'gym' }),
    Climb.belongsTo('holds', { key: 'hold_id', field: 'hold' }),
    Climb.belongsTo('walls', { key: 'wall_id', field: 'wall' }),
    Climb.belongsTo('setters', { key: 'setter_id', field: 'setter' }),
    Climb.hasMany('climb_groups', { key: 'climb_id' }),
    Climb.hasMany('comp_climbs', { key: 'climb_id' }),
    Climb.hasMany('ascends', { key: 'climb_id' }),
    Climb.hasMany('opinions', { key: 'climb_id' }),
  ]

  static parsers = {
    grade: parseFloatSafe,
    grade_stability: parseFloatSafe,
    grade_stability_admin: parseFloatSafe,
  }

  static apiActions = {
    updateMany: {
      methodUrl: '/update_many',
      method: 'PUT',
    },
    checkMany: {
      methodUrl: '/check_many',
      method: 'PUT',
    },
    stats: {
      methodUrl: '/:climb_id/stats',
    },
    statsAdmin: {
      methodUrl: '/:climb_id/stats_admin',
    },
  }

  static getCommonChecks(climbs, { exact = false } = {}) {
    let checksPerClimb = climbs.map(c => c.checks)
    if (exact) {
      return checksPerClimb.every(checks => checks == checksPerClimb[0]) ? checksPerClimb[0] : 0
    } else {
      return arrayMin(checksPerClimb, 0)
    }
  }

  static getCommonTops(climbs) {
    let topsPerClimb = climbs.map(c => c.tops)
    return arrayMin(topsPerClimb, 0)
  }

  static getTopped(climbs) {
    return climbs.filter(c => c.checks)
  }

  get displayName() {
    return this.name || (this.wall && this.wall.name) || ''
  }
  get climbGroupNames() {
    return this.climb_groups.map(cg => cg.group.name).join(',')
  }
  get isNew() {
    return this.gym.isNew(this.date_set)
  }
  get removedSoon() {
    return this.gym.removedSoon(this.date_removed)
  }
  get removedSooner() {
    return this.gym.removedSooner(this.date_removed)
  }
  get removedExpired() {
    return this.gym.removedExpired(this.date_removed)
  }
  get checks() {
    if (store.state.climbs && store.state.climbs.checksFunc) return store.state.climbs.checksFunc(this)
    const topChecks = this.ascendsFiltered(Infinity).map(a => a.checks)
    return arrayMax(topChecks, 0)
  }
  get maxChecks() {
    return this.climb_type == 'boulder' ? 2 : 3
  }
  get tops() {
    return this.ascendsFiltered(Infinity).length
  }
  get gradeStability() {
    return this.auto_grade ? this.grade_stability : this.grade_stability_admin
  }
  get opinion() {
    return this.opinions.find(opinion => opinion.user_id == store.getters.userViewed.id)
  }

  firstAscend(zone) {
    // The ascend with the highest checks for this zone, with tiebraker on first date_logged.
    return this.ascendsFiltered(zone).sort((a, b) => b.checks - a.checks || a.date_logged - b.date_logged)[0]
  }

  lastAscend(zone) {
    // The ascend with the lowest checks for this zone, with tiebraker on lastest date_logged.
    return this.ascendsFiltered(zone).sort((a, b) => a.checks - b.checks || b.date_logged - a.date_logged)[0]
  }

  // If checks is undefined, all with checks are selected.
  // If zone is Infinity, only tops are selected.
  ascendsFiltered(zone, checks) {
    const viewFilter = store.hasModule('climbs') ? store.getters['climbs/viewFilters/ascendFilter'] : () => true
    const zonesFilter = ascend => (zone ? ascend.zoneOrInf == zone : true)
    const checksFilter = ascend => (checks ? ascend.checks == checks : ascend.checks)
    return this.ascends
      .filter(viewFilter)
      .filter(zonesFilter)
      .filter(checksFilter)
      .slice()
      .sort((a, b) => b.date_logged - a.date_logged) // Most recent first
  }

  toggleLive(newVal) {
    let newVals = {}
    newVals.live = typeof newVal !== 'undefined' ? !!newVal : !this.live
    if (newVals.live) {
      newVals.lived = true
      newVals.date_live_start = this.date_live_start || new Date().toISOString()
      newVals.date_set = this.date_set || new Date().toISOString()
    } else if (this.lived) {
      newVals.date_live_end = new Date().toISOString()
    }
    this.$update(newVals)
    return this.$apiSave()
  }
}
