/* eslint-disable */
export default {
  key: 'uiaa',
  name: 'UIAA',
  description: 'International Union of Alpine Associations',
  types: ['route'],
  data: [
    { value: 2,    name: 'III',   level: 0 },
    { value: 3,    name: 'IV-',   level: 0, name0: 'IV/V', name1: 'IV' }, // 3a
    { value: 3.44, name: 'IV',    level: 2 },
    { value: 3.89, name: 'IV+',   level: 2 },
    { value: 4.33, name: 'V-',    level: 1, name1: 'V' }, // 4b
    { value: 4.67, name: 'V',     level: 2 }, // 4c
    { value: 4.92, name: 'V+',    level: 2 },
    { value: 5.21, name: 'VI-',   level: 0, name0: 'VI/VII', name1: 'VI' },
    { value: 5.50, name: 'VI',    level: 2 }, // 5b+
    { value: 5.78, name: 'VI+',   level: 2 },
    { value: 6.06, name: 'VII-',  level: 1, name1: 'VII' },
    { value: 6.33, name: 'VII',   level: 2 }, // 6b
    { value: 6.56, name: 'VII+',  level: 2 },
    { value: 6.78, name: 'VIII-', level: 0, name0: 'VIII/IX', name1: 'VIII' },
    { value: 7,    name: 'VIII',  level: 2 }, //7a
    { value: 7.22, name: 'VIII+', level: 2 },
    { value: 7.44, name: 'IX-',   level: 1, name1: 'IX' },
    { value: 7.67, name: 'IX',    level: 2 }, // 7c
    { value: 7.89, name: 'IX+',   level: 2 },
    { value: 8.11, name: 'X-',    level: 0, name0: 'X/XI', name1: 'X' },
    { value: 8.33, name: 'X',     level: 2 }, // 8b
    { value: 8.56, name: 'X+',    level: 2 },
    { value: 8.78, name: 'XI-',   level: 1, name1: 'XI' },
    { value: 9,    name: 'XI',    level: 2 }, // 9a
    { value: 9.22, name: 'XI+',   level: 2 },
    { value: 9.44, name: 'XII-',  level: 0, name0: 'XII' },
  ]
}
