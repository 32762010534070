export function refreshIfOld(versionRequired) {
  if (!versionRequired) return

  let versionCurrent = localStorage.getItem('toplogger-version')

  if (!versionCurrent || versionCurrent != versionRequired) {
    localStorage.setItem('toplogger-version', versionRequired)
    location.reload(true)
  }
}
