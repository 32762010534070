<template>
  <v-app v-resize="setVh">
    <tl-toolbar />
    <tl-nav :style="{ height: '' }" />
    <tl-banner />
    <v-content class="tl-content-hex-bg" :class="{ 'tl-content-hex-bg__admin': isAdmin }">
      <router-view />
    </v-content>
    <tl-nav-right :style="{ height: '' }" />
    <tl-toast />
    <tl-dialog />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import tlNav from './nav/tl-nav.vue'
import tlToolbar from './toolbar/tl-toolbar.vue'
import tlNavRight from './nav-right/tl-nav-right.vue'
import tlToast from './toast/tl-toast.vue'
import tlDialog from './dialog/tl-dialog.vue'
import tlBanner from './toolbar/tl-banner.vue'
import localeGuard from '@/services/i18n/locale.guard.js'

export default {
  components: {
    tlNav,
    tlToolbar,
    tlNavRight,
    tlToast,
    tlDialog,
    tlBanner,
  },
  computed: {
    ...mapState(['isAdmin']),
  },
  created() {
    this.$store.dispatch('cordova/init')
  },
  mounted() {
    this.$store.commit('cordova/setLayoutMounted')
  },
  methods: {
    setVh() {
      // TODO: Use document.documentElement.clientHeight instead of window.innerHeight, and test it.
      // https://stackoverflow.com/a/54812656/3071369

      // Set custom css var --vh on root of the document
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
  },
  beforeRouteUpdate: localeGuard,
}
</script>

<style lang="sass">
// The vh unit isn't 100% of the view (thanks Apple), so in javascript we calc the real view height and set a css var.
// Vuetify uses the vh unit on just two locations: in the v-application-wrap and in the v-navigation-drawer.
// https://developers.google.com/web/updates/2016/12/url-bar-resizing
.v-application--wrap
  min-height: calc(var(--vh, 1vh) * 100) !important
.v-navigation-drawer
  height: calc(var(--vh, 1vh) * 100)

.tl-content-hex-bg
  background-image: url('./tl-hex-bg.svg')
  background-color: #f1f1f1
  background-size: 25px
  background-repeat: repeat
  &__admin
    background-image: url('./tl-hex-bg-admin.svg')
    background-color: #2d2d2d
</style>
