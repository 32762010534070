import french from './french'
import frenchBoulder from './french-boulder'
import frenchRounded from './french-rounded'
import yds from './yds'
import uiaa from './uiaa'
import ewbank from './ewbank'
import vGrade from './v-grade'
import british from './british'
export default {
  french,
  french_boulder: frenchBoulder,
  french_rounded: frenchRounded,
  yds,
  uiaa,
  ewbank,
  v_grade: vGrade,
  british,
}
