import Model from '@/services/orm/Model'
import { parseDateSafe } from '@/services/parsers'

export default class Group extends Model {
  static name = 'groups'

  static relations = [
    Group.belongsTo('gyms', { key: 'gym_id', field: 'gym' }),
    Group.belongsTo('holds', { key: 'hold_id', field: 'hold' }),
    Group.hasMany('climb_groups', { key: 'group_id' }),
  ]

  static parsers = {
    date_set: parseDateSafe,
    date_removed: parseDateSafe,
    date_live_start: parseDateSafe,
    date_live_end: parseDateSafe,
    date_loggable_start: parseDateSafe,
    date_loggable_end: parseDateSafe,
  }
}
