import Model from '@/services/orm/Model'

export default class CompUser extends Model {
  static name = 'comp_users'
  static endpoint = 'group_users'

  static relations = [
    CompUser.belongsTo('comps', { key: 'group_id', field: 'group' }),
    CompUser.belongsTo('users', { key: 'user_id', field: 'user' }),
    CompUser.belongsTo('poules', { key: 'poule_id', field: 'poule' }),
  ]

  // Temp getter until the backend has been rewritten and models named properly
  get comp() {
    return this.group
  }
}
