export function parseInteger(val) {
  return parseInt(val, 10)
}

export function parseFloatSafe(val) {
  let parsed = parseFloat(val)
  return isNaN(parsed) ? undefined : parsed
}

export function parseDateSafe(val) {
  return val ? new Date(val) : null
}
