import Vue from 'vue'

export default class Index {
  constructor() {
    this.namespaced = true
    this.state = {}
    this.mutations = this.createMutations()
    this.actions = this.createActions()
  }

  createMutations() {
    return {
      add: (state, { foreignId, obj }) => {
        if (!state[foreignId]) Vue.set(state, foreignId, [])
        if (state[foreignId].includes(obj)) return
        state[foreignId].push(obj)
      },
      remove: (state, { foreignId, obj }) => {
        if (!state[foreignId]) return
        let index = state[foreignId].indexOf(obj)
        if (index < 0) return
        state[foreignId].splice(index, 1)
        if (!state[foreignId].length) Vue.delete(state, foreignId)
      },
    }
  }

  createActions() {
    return {
      add: ({ commit }, { foreignId, obj }) => {
        if (!foreignId) return // This instance doesn't have the foreignKey, so no index to be added to.
        commit('add', { foreignId, obj })
      },
      remove: ({ commit }, { foreignId, obj }) => {
        if (!foreignId) return // This instance doesn't have the foreignKey, so no index to be removed from.
        commit('remove', { foreignId, obj })
      },
    }
  }
}
