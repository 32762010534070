export default [
  {
    path: ':climbsType(routes|boulders|climbs)?',
    name: 'climbs',
    component: () => import(/* webpackChunkName: 'tl-climbs' */ './logging/tl-climbs-logging-page.vue'),
    children: [
      {
        path: 'selection/:climbId(\\d+|multi)', // Restrict climbId to be all numbers
        name: 'climbs.climb',
        component: () => import(/* webpackChunkName: 'tl-climbs' */ './logging/dialog/tl-climbs-logger'),
        meta: { inPageRoute: true },
      },
    ],
  },
]
