export default [
  {
    path: 'comps',
    name: 'comps',
    component: () => import(/* webpackChunkName: 'tl-comps' */ './tl-comps.vue'),
  },
  {
    path: 'comp/:compId',
    name: 'comp',
    component: () => import(/* webpackChunkName: 'tl-comps' */ './comp/tl-comp.vue'),
    redirect: { name: 'comp.details' },
    children: [
      {
        path: 'details',
        name: 'comp.details',
        component: () => import(/* webpackChunkName: 'tl-comps' */ './comp/tl-comp-details.vue'),
        meta: { backRoute: { name: 'comps' } },
      },
      {
        path: 'climbs',
        name: 'comp.climbs',
        component: () => import(/* webpackChunkName: 'tl-climbs' */ './comp/tl-comp-logging.vue'),
        children: [
          {
            path: 'selection/:climbId(\\d+|multi)', // Restrict climbId to be all numbers
            name: 'comp.climbs.climb',
            component: () => import(/* webpackChunkName: 'tl-climbs' */ '../climbs/logging/dialog/tl-climbs-logger'),
            meta: { inPageRoute: true },
          },
        ],
      },
    ],
  },
]
