import ModelModule from './ModelModule'

export default class ModelsModule {
  constructor(models) {
    this.namespaced = true
    this.modules = this.createModules(models)
    this.actions = this.createActions()
  }

  createModules(models) {
    return models.reduce((modules, model) => {
      modules[model.name] = new ModelModule(model)
      return modules
    }, {})
  }

  createActions() {
    return {
      clear: ({ dispatch }) => {
        Object.keys(this.modules).forEach(module => {
          dispatch(`${module}/ejectAll`)
        })
      },
    }
  }
}
