import Model from '@/services/orm/Model'

export default class Poule extends Model {
  static name = 'poules'
  static urlParent = 'group'

  static relations = [
    Poule.belongsTo('comps', { key: 'group_id', field: 'group' }),
    Poule.hasMany('comp_users', { key: 'poule_id', field: 'group_users' }),
  ]

  // Temp getter until the backend has been rewritten and models named properly
  get comp() {
    return this.group
  }
  get comp_users() {
    return this.group_users
  }
}
