export default [
  {
    path: 'communications',
    name: 'gymComm',
    meta: { requiresAuth: 'isSetter' },
    component: () => import(/* webpackChunkName: 'tl-admin-comm' */ './tl-gym-comm.vue'),
    children: [
      {
        path: 'notifications/new',
        name: 'gymComm.newNotification',
        component: () => import(/* webpackChunkName: 'tl-admin-comm' */ './n11s/tl-n11s-admin-edit.vue'),
        meta: { inPageRoute: true },
      },
      {
        path: 'notifications/edit/:notificationId',
        name: 'gymComm.editNotification',
        component: () => import(/* webpackChunkName: 'tl-admin-comm' */ './n11s/tl-n11s-admin-edit.vue'),
        props: true,
        meta: { inPageRoute: true },
      },
    ],
  },
]
