export default [
  {
    path: 'comps',
    name: 'admin.comps',
    meta: { requiresAuth: 'isSetter' },
    component: () => import(/* webpackChunkName: 'tl-comps' */ '@/components/gym/comps/tl-comps.vue'),
  },
  {
    path: 'comp/:compId',
    name: 'admin.comp',
    meta: { requiresAuth: 'isSetter' },
    redirect: { name: 'admin.comp.edit' },
    component: () => import(/* webpackChunkName: 'tl-comps' */ '@/components/gym/comps/comp/tl-comp.vue'),
    children: [
      {
        path: 'edit',
        name: 'admin.comp.edit',
        meta: { backRoute: { name: 'admin.comps' } },
        component: () => import(/* webpackChunkName: 'tl-edit-comps' */ './tl-comp-edit.vue'),
      },
      {
        path: 'climbs',
        name: 'admin.comp.climbs',
        component: () => import(/* webpackChunkName: 'tl-edit-comps' */ './tl-comp-climbs-select.vue'),
      },
      {
        path: 'participants',
        name: 'admin.comp.participants',
        meta: { backRoute: { name: 'admin.comps' } },
        component: () => import(/* webpackChunkName: 'tl-edit-comps' */ './tl-comp-participants.vue'),
      },
      {
        path: 'results',
        name: 'admin.comp.results',
        meta: { backRoute: { name: 'admin.comps' } },
        component: () => import(/* webpackChunkName: 'tl-edit-comps' */ './tl-comp-results.vue'),
      },
    ],
  },
]
