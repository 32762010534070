/* eslint-disable */
export default {
  key: 'british',
  name: 'British',
  description: 'British Peak bouldering grade system',
  types: ['boulder'],
  data: [
    { value: 2,    name: 'B0',  level: 0 },
    { value: 3,    name: 'B1',  level: 0, name0: 'B1-B4', name1: 'B1-B2' },
    { value: 3.83, name: 'B2',  level: 2 },
    { value: 5,    name: 'B3',  level: 1, name1: 'B3-B4' },
    { value: 6,    name: 'B4',  level: 2 }, // Compare-reference grade
    { value: 6.28, name: 'B5',  level: 0, name0: 'B5-B8', name1: 'B5-B6' },
    { value: 6.56, name: 'B6',  level: 2 },
    { value: 6.83, name: 'B7',  level: 1, name1: 'B7-B8' },
    { value: 7.11, name: 'B8',  level: 2 },
    { value: 7.39, name: 'B9',  level: 0, name0: 'B9-B12', name1: 'B9-B10' },
    { value: 7.67, name: 'B10', level: 2 }, // Compare-reference grade
    { value: 7.87, name: 'B11', level: 1, name1: 'B11-B12' },
    { value: 8.08, name: 'B12', level: 2 },
    { value: 8.29, name: 'B13', level: 0, name0: 'B13-B16', name1: 'B13-B14' },
    { value: 8.5,  name: 'B14', level: 2 }, // Compare-reference grade
    { value: 8.67, name: 'B15', level: 1, name1: 'B15-B16' },
    { value: 8.83, name: 'B16', level: 2 },
    { value: 9,    name: 'B17', level: 0 },
  ]
}
