import { schema } from 'normalizr'
import ModelsModule from './ModelsModule'
let alwaysIncreasing = 0

export default class Database {
  // 1. Initialize a database:
  constructor() {
    this.models = []
  }

  // 2. Register each model on the database and intialize it's relations:
  register(model) {
    const schemaOptions = {
      idAttribute: model.primaryKey,
      processStrategy(value) {
        if (!Object.prototype.hasOwnProperty.call(value, model.primaryKey)) {
          value[model.primaryKey] = model.getters('newNegativeId') - alwaysIncreasing++
        }
        return { ...value } // Return shallow copy as recommended in the normalizr docs
      },
    }
    model.schema = new schema.Entity(model.name, undefined, schemaOptions)
    this.models.push(model)
    this.initRelations({ model })
  }

  initRelations({ model, recurse = true }) {
    // Define the relations on this model
    model.relations.forEach(relation => {
      if (relation.relatedModel) return // This relation was already initialized.
      relation.relatedModel = this.models.find(m => m.name === relation.relatedModelName)
      if (!relation.relatedModel) return // The model for this relation was not yet registered.
      relation.init()
    })
    // Check all other models for uninitialized relations. The inverse relations to this model can now be initialized too:
    if (!recurse) return // Prevent infinite recursion
    this.models.filter(m => m.name !== model.name).forEach(model => this.initRelations({ model, recurse: false }))
  }

  // 3. Register the database as module on the vuex store:
  addToStore({ store, namespace }) {
    this.store = store
    this.namespace = namespace
    this.registerModules()
  }

  registerModules() {
    this.store.registerModule(this.namespace, new ModelsModule(this.models), {
      preserveState: !!this.store[this.namespace],
    })
  }

  findModel(name) {
    return this.models.find(m => m.name === name)
  }

  clear() {
    this.store.dispatch(`${this.namespace}/clear`)
  }
}
