<template>
  <v-navigation-drawer
    :value="expanded"
    :hide-overlay="hideOverlay"
    :touchless="!component"
    app
    fixed
    right
    dark
    width="320"
    disable-resize-watcher
    @input="toggle"
    @transitionend="toggleOverlay"
  >
    <component :is="component" />
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data: () => ({
    hideOverlay: true,
  }),
  computed: {
    ...mapState('nav-right', ['expanded', 'component']),
  },
  methods: {
    ...mapMutations('nav-right', ['toggle']),
    toggleOverlay() {
      // Required due to a bug in the nav-drawer when the component inside takes some time to render.
      // Occurs when double-clicking on a boulder in the editor, yielding a blocking overlay.
      this.hideOverlay = !this.expanded || this.$vuetify.breakpoint.lgAndUp
    },
  },
}
</script>
