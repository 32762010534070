/* eslint-disable */
export default {
  key: 'v_grade',
  name: 'V-scale',
  description: 'Hueco Vermin bouldering grade system',
  types: ['boulder'],
  data: [
    { value: 2,    name: 'VB',  level: 0 },
    { value: 3,    name: 'VB',  level: 0 },
    { value: 3.5,  name: 'V0-', level: 0, name0: 'V0', name1: 'V0' },
    { value: 4,    name: 'V0',  level: 2 },
    { value: 4.5,  name: 'V0+', level: 2 },
    { value: 5,    name: 'V1',  level: 0, name0: 'V1-4', name1: 'V1-2' },
    { value: 5.5,  name: 'V2',  level: 2 },
    { value: 6,    name: 'V3',  level: 1, name1: 'V3-4' },
    { value: 6.33, name: 'V4',  level: 2 },
    { value: 6.67, name: 'V5',  level: 0, name0: 'V5-8', name1: 'V5-6' },
    { value: 7,    name: 'V6',  level: 2 },
    { value: 7.2,  name: 'V7',  level: 1, name1: 'V7-8' },
    { value: 7.4,  name: 'V8',  level: 2 },
    { value: 7.6,  name: 'V9',  level: 0, name0: 'V9-12', name1: 'V9-10' },
    { value: 7.8,  name: 'V10', level: 2 },
    { value: 8,    name: 'V11', level: 1, name1: 'V11-12' },
    { value: 8.17, name: 'V12', level: 2 },
    { value: 8.33, name: 'V13', level: 0, name0: 'V13-16', name1: 'V13-14' },
    { value: 8.5,  name: 'V14', level: 2 },
    { value: 8.67, name: 'V15', level: 1, name1: 'V15-16' },
    { value: 8.83, name: 'V16', level: 2 },
    { value: 9,    name: 'V17', level: 0 },
  ]
}
