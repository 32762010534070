export default [
  {
    path: 'reservations',
    name: 'admin.reservations',
    component: () => import(/* webpackChunkName: 'tl-admin-reservations' */ './tl-reservations-admin.vue'),
  },
  {
    path: 'reservations/no-shows',
    name: 'admin.reservations.noShows',
    component: () => import(/* webpackChunkName: 'tl-admin-reservations' */ './tl-reservations-admin-no-shows.vue'),
  },
  {
    path: 'reservations/cancellations',
    name: 'admin.reservations.cancellations',
    component: () =>
      import(/* webpackChunkName: 'tl-admin-reservations' */ './tl-reservations-admin-cancellations.vue'),
  },
  {
    path: 'reservations/table',
    name: 'admin.reservations.table',
    component: () => import(/* webpackChunkName: 'tl-admin-reservations' */ './tl-reservations-admin-table.vue'),
  },
  {
    path: 'reservations/slots',
    name: 'admin.reservations.slots',
    component: () => import(/* webpackChunkName: 'tl-admin-reservations' */ './slots/tl-reservations-admin-slots.vue'),
  },
  {
    path: 'reservations/settings',
    name: 'admin.reservations.settings',
    component: () =>
      import(/* webpackChunkName: 'tl-admin-reservations' */ './settings/tl-reservations-admin-settings.vue'),
  },
]
