import Autosave from './Autosave'

export default class AutosavePlugin {
  static install(config = {}) {
    return store => {
      config.store = store
      return new Autosave(config)
    }
  }
}
