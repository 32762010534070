<template>
  <router-view />
</template>

<script>
import gymGuard from './gym.guard'

export default {
  beforeRouteUpdate: gymGuard,
}
</script>
