import Model from '@/services/orm/Model'
import { parseDateSafe } from '@/services/parsers'

export default class Feedback extends Model {
  static name = 'feedbacks'

  static relations = [
    Feedback.belongsTo('gyms', { key: 'gym_id', field: 'gym' }),
    Feedback.belongsTo('users', { key: 'user_id', field: 'user' }),
  ]

  static parsers = {
    // id: this.increment(),
    // gym_id: this.attr(null),
    // user_id: this.attr(null),
    // message: this.string(null).nullable(),
    // reply: this.string(null).nullable(),
    // label: this.string(null).nullable(),
    // retracted: this.boolean(false),
    created_at: parseDateSafe,
    updated_at: parseDateSafe,
    replied_at: parseDateSafe,
  }
}
