import compsRoutes from './comps/comps.routes'
import commRoutes from './comm/comm.routes'
import reservationsRoutes from './reservations/reservations.routes'
import statsRoutes from './stats/gym-stats.routes'
import settingsRoutes from './settings/settings.routes'

export default [
  {
    path: 'admin',
    name: 'admin',
    redirect: { name: 'editClimbs' },
    component: () => import(/* webpackChunkName: 'tl-admin' */ './tl-admin.vue'),
    children: [
      ...compsRoutes,
      ...commRoutes,
      ...reservationsRoutes,
      ...statsRoutes,
      ...settingsRoutes,
      {
        path: 'edit-:climbsType(climbs|routes|boulders)?',
        name: 'editClimbs',
        meta: {
          requiresAuth: 'isSetter',
          backRoute: { name: 'dashboard' },
        },
        component: () => import(/* webpackChunkName: 'tl-admin' */ '../climbs/edit/tl-climbs-edit.vue'),
      },
    ],
  },
]
