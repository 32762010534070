import router from '@/router'
import sha1 from 'simple-sha1'

const KIOSK_INTERVAL_FAST = 6000
const KIOSK_INTERVAL_SLOW = 30000

// Define wrapperfunction, as we need a reference to the function for unbinding:
let restartInterval = () => {}
let restartIntervalWrapper = () => restartInterval(KIOSK_INTERVAL_SLOW)
const state = () => ({
  isKiosk: /kiosk.*\.toplogger\.nu/.test(document.domain),
  kioskGymSlug: localStorage.getItem('kiosk_locked_gym_slug'),
  kioskResetInterval: null,
  showGetAppDialog: true,
})

const getters = {
  isKioskLocked: state => state.isKiosk && !!state.kioskGymSlug,
  isKioskUnlocked: state => state.isKiosk && !state.kioskGymSlug,
}

const mutations = {
  setKioskGymSlug(state, gymSlug) {
    state.kioskGymSlug = gymSlug
    if (gymSlug) {
      localStorage.setItem('kiosk_locked_gym_slug', gymSlug)
    } else {
      localStorage.removeItem('kiosk_locked_gym_slug')
    }
  },
  setShowGetAppDialog(state, newVal) {
    state.showGetAppDialog = !!newVal
  },
}

const actions = {
  init({ state, getters, dispatch }) {
    if (!state.isKiosk) return
    getters.isKioskLocked ? dispatch('startKioskMode') : dispatch('stopKioskMode')
  },
  startKioskMode({ commit, dispatch }, gymSlug) {
    if (gymSlug) commit('setKioskGymSlug', gymSlug)
    dispatch('toggleResetInterval', true)
  },
  stopKioskMode({ commit, dispatch }) {
    commit('setKioskGymSlug', null)
    dispatch('toggleResetInterval', false)
  },
  toggleResetInterval({ state, dispatch }, enable) {
    let intervalFunc = () => {
      dispatch('kioskReset')
      restartInterval(KIOSK_INTERVAL_FAST)
    }
    let startInterval = interval => (state.kioskResetInterval = setInterval(intervalFunc, interval))
    let stopInterval = () => (state.kioskResetInterval = clearInterval(state.kioskResetInterval))
    restartInterval = interval => {
      stopInterval()
      startInterval(interval)
    }

    if (enable) {
      startInterval(KIOSK_INTERVAL_FAST)
      document.body.addEventListener('mousedown', restartIntervalWrapper, true)
      document.body.addEventListener('touchstart', restartIntervalWrapper, true)
    } else {
      stopInterval()
      document.body.removeEventListener('mousedown', restartIntervalWrapper, true)
      document.body.removeEventListener('touchstart', restartIntervalWrapper, true)
    }
  },
  kioskReset({ state, commit, dispatch }) {
    commit('kiosk/setShowGetAppDialog', true)
    if (router.currentRoute.name != 'climbs' || router.currentRoute.query.uid != null) {
      router.push({ name: 'climbs', params: { gymSlug: state.kioskGymSlug } })
    } else {
      dispatch('climbs/kioskReset', null, { root: true })
    }
  },
  async authKioskMode({ getters, dispatch }, { gym }) {
    let isLogin = !getters.isKioskLocked
    let password = await dispatch(
      'dialog/prompt',
      {
        title: `${isLogin ? 'Lock' : 'Unlock'} at ${gym.name}`,
        text: isLogin ? 'Enter the logout password in order to lock the device at this gym.' : '',
        type: 'password',
        ok: isLogin ? 'Lock' : 'Unlock',
      },
      { root: true }
    )
    let passwordValid = password && sha1.sync(password) == gym.local_device_pwd
    if (password && !passwordValid) {
      dispatch('toast/error', 'Wrong password, You can change the password in the gym settings page.', { root: true })
    }
    if (passwordValid) isLogin ? dispatch('startKioskMode', gym.slug) : dispatch('stopKioskMode')
    return passwordValid
  },
  showGetAppDialog({ state, dispatch }) {
    if (!state.showGetAppDialog) return
    dispatch(
      'dialog/open',
      {
        component: () =>
          import(/* webpackChunkName: 'tl-kiosk' */ '@/components/shared/tl-get-app-dialog/tl-get-app-dialog'),
        props: { maxWidth: 900 },
      },
      { root: true }
    )
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
