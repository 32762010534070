<template>
  <v-dialog :value="visible" :max-width="maxWidth" :fullscreen="fullscreen" scrollable @input="close()">
    <component :is="component" v-bind="componentProps" />
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState('dialog', ['component', 'componentProps', 'visible', 'fullscreen', 'maxWidth']),
  },
  methods: {
    ...mapActions('dialog', ['close']),
  },
}
</script>
