import OrmPlugin from '@/services/orm'
import OrmAxiosPlugin from '@/services/orm-axios'
import { parseInteger } from '@/services/parsers'
import database from '@/services/database'
import store from '@/store'
import i18n from '@/plugins/i18n'
import errorService from '@/services/error-service'
import { refreshIfOld } from '@/services/version-service'

const ormPluginConfig = {
  modelParsers: {
    id: parseInteger,
  },
}

const ormAxiosPluginConfig = {
  baseURL: process.env.VUE_APP_URL_API + '/v1',
  suffix: '.json',
  onInterceptRequest: config => {
    let email = localStorage.getItem('user_email')
    let token = localStorage.getItem('user_token')
    if (email && token) {
      config.headers['X-USER-EMAIL'] = email
      config.headers['X-USER-TOKEN'] = token
    }
    return config
  },
  onGenericError: errorService.toast,
  onNetworkError: () => {
    store.dispatch('toast/error', i18n.t('generic.badConnection'))
  },
  onInterceptSuccess(response) {
    refreshIfOld(response.headers['frontend-version'])
    return response.data
  },
}

OrmPlugin.use(OrmAxiosPlugin, ormAxiosPluginConfig)
const installFunction = OrmPlugin.install(database, ormPluginConfig)
installFunction(store)
