<template>
  <v-app v-resize="setVh">
    <v-content>
      <router-view />
    </v-content>
    <tl-toast />
    <tl-dialog />
  </v-app>
</template>

<script>
import tlToast from './toast/tl-toast.vue'
import tlDialog from './dialog/tl-dialog.vue'
import localeGuard from '@/services/i18n/locale.guard.js'

export default {
  components: {
    tlToast,
    tlDialog,
  },
  methods: {
    setVh() {
      // Set custom css var --vh on root of the document
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
  },
  beforeRouteUpdate: localeGuard,
}
</script>

<style lang="sass">
// The vh unit isn't 100% of the view (thanks Apple), so in javascript we calc the real view height and set a css var.
// Vuetify uses the vh unit on just two locations: in the v-application-wrap and in the v-navigation-drawer.
// https://developers.google.com/web/updates/2016/12/url-bar-resizing
.v-application--wrap
  min-height: calc(var(--vh, 1vh) * 100) !important
.v-navigation-drawer
  height: calc(var(--vh, 1vh) * 100)
</style>
