import store from '@/store'
import i18n from '@/plugins/i18n'

export function beforeEach(to, from, next) {
  let requiresSetter = to.matched.some(route => route.meta.requiresAuth == 'isSetter')
  let requiresAdmin = to.matched.some(route => route.meta.requiresAuth == 'isAdminSetter')
  let unauthorizedSetter = requiresSetter && !store.state.user.isSetter
  let unauthorizedAdmin = requiresAdmin && !store.state.user.isAdminSetter

  if (unauthorizedSetter || unauthorizedAdmin) {
    store.dispatch('toast/error', i18n.t('auth.unauthorized'))
    return next({ name: 'auth.login', replace: true })
  }

  next()
}

export function beforeRouteEnter(to, from, next) {
  let dashboardRoute = { name: 'dashboard', replace: true }
  // Prevent access in kiosk
  if (store.state.kiosk.isKiosk) {
    if (store.getters['kiosk/isKioskLocked']) {
      return next(dashboardRoute)
    } else {
      return next({ name: 'gyms', replace: true })
    }
  }

  // Handle messages set by the server (e.g. after email confirmation):
  if (to.query.message) store.dispatch('toast/success', to.query.message)
  if (to.query.error) store.dispatch('toast/error', to.query.error)

  // Handle omniauth callbacks:
  let { email, token, user_id } = to.query
  if (email && token && user_id) {
    store.commit('auth/setLoggedIn', { email, token, user_id })
    store.dispatch('toast/success', i18n.t('auth.msgLoggedIn'))
    return next(dashboardRoute)
  }

  // Redirect if already logged in:
  if (store.getters['auth/isAuth']) {
    return next(dashboardRoute)
  }
  next()
}
