import Vue from 'vue'
import Normalizer from './Normalizer'
import IndexesModule from './IndexesModule'
import ChangesModule from './ChangesModule'

export default class ModelModule {
  constructor(model) {
    this.namespaced = true
    this.state = {
      $name: model.name,
      data: {},
    }
    this.modules = {
      indexes: new IndexesModule(model.relations, model.indexes),
      changes: new ChangesModule(),
    }
    this.getters = ModelModule.getters
    this.mutations = ModelModule.mutations
    this.actions = ModelModule.actions
  }

  static getters = {
    all: state => Object.values(state.data),
    find: state => id => state.data[id],
    findBy: (state, getters) => (...args) => {
      return getters.findAllBy(...args)[0]
    },
    findAllBy: state => (index, foreignId) => {
      return state.indexes[index][foreignId] || []
    },
    isNew: () => instance => {
      return !instance.$id || instance.$id < 0
    },
    newNegativeId: state => {
      const ids = Object.keys(state.data)
      let newId = Math.min(...ids) - 1
      if (newId >= 0) newId = -1
      return newId
    },
  }

  static mutations = {
    inject: (state, instance) => {
      Vue.set(state.data, instance.$id, instance) // Define reactive: https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
    },
    update: (undefined, { instance, changes }) => {
      Object.keys(changes).forEach(prop => {
        Vue.set(instance, prop, changes[prop])
      })
    },
    updateId: (state, { oldId, newId }) => {
      // Move the instance to it's new key:
      Vue.set(state.data, newId, state.data[oldId])
      Vue.delete(state.data, oldId)
      // Update the id of the instance:
      state.data[newId].$id = newId
    },
    eject: (state, instance) => {
      Vue.delete(state.data, instance.$id)
    },
  }

  static actions = {
    inject: ({ dispatch }, { data, config }) => {
      const method = data instanceof Array ? 'injectMany' : 'injectOne'
      return dispatch(method, { data, config })
    },
    injectOne: async ({ dispatch }, { data, config }) => {
      let instances = await dispatch('injectMany', { data: [data], config })
      return instances[0]
    },
    injectMany: ({ state, dispatch }, { data, config }) => {
      const normalized = Normalizer.process(data, state.$name)
      return normalized.reduce((injectedHere, { model, instances }) => {
        let injected = dispatch(`${model.namespace()}/injectManyNormalized`, { instances, config }, { root: true })
        return model.name === state.$name ? injected : injectedHere // Only return instances injected in this module
      }, [])
    },
    injectManyNormalized: async ({ dispatch }, { instances, config }) => {
      return await Promise.all(
        instances.map(instance => {
          return dispatch('injectOneNormalized', { instance, config })
        })
      )
    },
    injectOneNormalized: ({ getters, commit, dispatch }, { instance, config }) => {
      let existing = getters.find(instance.$id)
      if (existing) {
        return dispatch('update', { instance: existing, changes: instance, config })
      } else {
        commit('inject', instance)
        dispatch('indexes/inject', instance)
        instance.$updateRelations()
        return instance
      }
    },
    update: ({ commit, dispatch }, { instance, changes, config }) => {
      config = config || {}
      // Filter actual changes:
      changes = Object.keys(changes || {}).reduce((acc, prop) => {
        if (JSON.stringify(instance[prop]) != JSON.stringify(changes[prop])) {
          acc[prop] = instance.$parseProp(prop, changes[prop])
        }
        return acc
      }, {})
      config.beforeUpdate && config.beforeUpdate({ instance, changes })
      // Handle possible id-changes:
      const newId = changes[instance.constructor.primaryKey]
      if (typeof newId !== 'undefined') {
        dispatch('updateId', { instance, newId })
        delete changes[instance.constructor.primaryKey] // Remove to prevent processing this change in the actual update below.
      }
      // Handle changes
      dispatch('indexes/update', { instance, changes }) // Update possible foreignKey changes. Do this before the actual update, otherwise we don't know the previous values
      dispatch('changes/update', { instance, changes })
      commit('update', { instance, changes }) // Update the instance
      instance.$updateRelations()
      config.afterUpdate && config.afterUpdate({ instance, changes })
      return instance
    },
    updateId: ({ state, commit, dispatch }, { instance, newId }) => {
      if (newId == instance.$id) return
      // Update belongsTo relations before they brake:
      // Loop through all hasMany relations to find instances having a foreignId that needs to be updated:
      const hasManyRelationFields = instance.constructor.relations.filter(rel => rel.localKey).map(rel => rel.field)
      hasManyRelationFields.forEach(relationField => {
        // Use the getter created by the relation to access all instances for this relation:
        if (!instance[relationField]) return
        instance[relationField].forEach(relatedInstance => {
          // Get the reverse relation (belongsTo) to get it's foreignKey (= the field)
          const belongsToRel = relatedInstance.constructor.relations.find(rel => rel.relatedModelName == state.$name)
          relatedInstance.$update({ [belongsToRel.foreignKey]: newId })
        })
      })

      const idChange = { oldId: instance.$id, newId }
      dispatch('changes/updateId', idChange)
      commit('updateId', idChange)
      instance.$updateRelations()
    },
    eject: ({ commit, dispatch }, instance) => {
      dispatch('indexes/eject', instance) // First remove from the indexes, as that requires it's id to still be set
      dispatch('changes/purge', { id: instance.$id })
      commit('eject', instance)
      instance.$updateRelations()
      return instance
    },
    ejectAll: ({ getters, dispatch }) => {
      let instances = getters.all
      instances.forEach(instance => {
        dispatch('eject', instance)
      })
      return instances
    },
    purgeChanges: ({ dispatch }, { instance, since, until }) => {
      dispatch('changes/purge', { id: instance.$id, since, until })
    },
    restore: ({ getters, dispatch }, { instance, backTo }) => {
      const fullChanges = getters['changes/changesDetailed']({ id: instance.$id, since: backTo })
      const changes = Object.keys(fullChanges).reduce((agg, prop) => {
        agg[prop] = fullChanges[prop].oldVal
        return agg
      }, {})
      instance.$update(changes)
      dispatch('changes/purge', { id: instance.$id, since: backTo })
      return instance
    },
  }
}
