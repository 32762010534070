import adminRoutes from './admin/admin.routes'
import compsRoutes from './comps/comps.routes'
import climbsRoutes from './climbs/climbs.routes'
import reservationsRoutes from './reservations/reservations.routes'
import tlGym from './tl-gym.vue'
import gymGuard from './gym.guard'

export default [
  {
    path: ':gymSlug?',
    name: 'gym',
    redirect: { name: 'dashboard' },
    component: tlGym,
    beforeEnter: gymGuard,
    children: [
      {
        path: 'dashboard/:climbsType(routes|boulders|climbs)?',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: 'tl-dashboard' */ './dashboard/tl-dashboard.vue'),
        meta: { backRoute: { name: 'auth.login' } },
      },
      ...compsRoutes,
      {
        path: 'my-gym',
        name: 'myGym',
        component: () => import(/* webpackChunkName: 'tl-my-gym' */ './my-gym/tl-my-gym.vue'),
      },
      ...reservationsRoutes,
      ...adminRoutes,
      ...climbsRoutes,
    ],
  },
]
