import { normalize } from 'normalizr'
import Container from './Container'

export default class Normalizer {
  static process(rawData, rootModelName) {
    const rootModel = Container.database.findModel(rootModelName)
    const normalized = normalize(rawData, [rootModel.schema])
    return Object.keys(normalized.entities).map(modelName => {
      const model = Container.database.findModel(modelName)
      const records = Object.values(normalized.entities[modelName])
      const instances = records.map(record => {
        return record instanceof model ? record : new model(record)
      })
      return { model, instances }
    })
  }
}
