export default class Relation {
  constructor({ ownerModel, relatedModelName, relatedModel, localKey, foreignKey, field }) {
    this.ownerModel = ownerModel
    this.relatedModelName = relatedModelName
    this.relatedModel = relatedModel
    this.localKey = localKey || null
    this.foreignKey = foreignKey || null
    this.field = field || relatedModelName
  }

  init() {
    // Add the relation to the normalizr schema of the ownerModel:
    this.ownerModel.schema.define({ [this.field]: this.getSchemaDef() })
  }

  getSchemaDef() {
    return this.relatedModel.schema
  }

  getGetter() {}

  // Searches the relatedModel for a relation pointing back to this relations ownerModel.
  getInverseRel() {
    return this.relatedModel.relations.find(subRel => subRel.relatedModelName == this.ownerModel.name)
  }
}

export class HasMany extends Relation {
  getSchemaDef() {
    return [this.relatedModel.schema]
  }

  getGetter() {
    const relation = this
    return function getter() {
      const ownId = this[relation.ownerModel.primaryKey]
      return relation.relatedModel.getters('findAllBy')(relation.localKey, ownId)
    }
  }
}

export class HasOne extends Relation {}

export class BelongsTo extends Relation {
  getGetter() {
    const relation = this
    return function getter() {
      return relation.relatedModel.find(this[relation.foreignKey])
    }
  }
}
