import authRoutes from '@/components/auth/auth.routes'
import gymRoutes from '@/components/gym/gym.routes'
import tlLayout from '@/components/layout/tl-layout.vue'
import tlEmpty from '@/components/layout/tl-empty.vue'
import localeGuard from '@/services/i18n/locale.guard'
import i18nStore from '@/services/i18n/i18n.store'

const locales = i18nStore.state.localeDefs.map(l => l.code)

export default [
  {
    path: '/cordova/save-platform',
    name: 'savePlatform',
    component: () => import(/* webpackChunkName: 'tl-cordova' */ '@/services/cordova/tl-save-platform.vue'),
  },
  {
    path: `/:locale(${locales.join('|')})?`,
    name: 'emptyPage',
    component: tlEmpty,
    beforeEnter: localeGuard,
    redirect: { name: 'auth.login' },
    children: [
      {
        path: 'reservations/check-out',
        name: 'checkOut',
        component: () =>
          import(/* webpackChunkName: 'tl-reservations-checkout' */ '@/components/gym/reservations/tl-checkout.vue'),
      },
    ],
  },
  {
    path: `/:locale(${locales.join('|')})?`,
    name: 'layout',
    component: tlLayout,
    beforeEnter: localeGuard,
    redirect: { name: 'auth.login' },
    children: [
      ...authRoutes,
      {
        path: 'profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: 'tl-profile' */ '@/components/profile/tl-profile.vue'),
      },
      {
        path: 'gyms',
        name: 'gyms',
        component: () => import(/* webpackChunkName: 'tl-gyms' */ '@/components/gyms/tl-gyms.vue'),
      },
      {
        path: 'help',
        name: 'help',
        component: () => import(/* webpackChunkName: 'tl-extras' */ '@/components/extras/help/tl-help.vue'),
      },
      {
        path: 'about',
        name: 'about',
        beforeEnter(to, from, next) {
          window.open('https://toplogger.nu/about', '_blank')
          next(false)
        },
      },
      {
        path: 'feedback',
        name: 'feedback',
        component: () => import(/* webpackChunkName: 'tl-extras' */ '@/components/extras/feedbacks/tl-feedback.vue'),
      },
      {
        path: 'terms-of-service',
        name: 'terms',
        component: () => import(/* webpackChunkName: 'tl-extras' */ '@/components/extras/tl-terms.vue'),
      },
      {
        path: 'mail-tester',
        name: 'mailtester',
        component: () => import(/* webpackChunkName: 'tl-mail-tester' */ '@/components/extras/tl-mail-tester.vue'),
      },
      {
        path: 'monitoring',
        name: 'monitoring',
        component: () => import(/* webpackChunkName: 'tl-monitoring' */ '@/components/extras/tl-monitoring.vue'),
      },
      ...gymRoutes,
      {
        path: '*',
        redirect: { name: 'auth.login' },
      },
    ],
  },
]
