import Vue from 'vue'

const state = () => ({
  msg: '',
  type: '',
  icon: 'tl-info',
})

const getters = {
  active: state => Boolean(state.msg),
}

const mutations = {
  show(state, options = {}) {
    Object.keys(options).forEach(field => (state[field] = options[field]))
  },
  hide(state) {
    state.msg = ''
  },
}

const actions = {
  info({ dispatch }, msg) {
    dispatch('show', { msg, type: '', icon: 'tl-info' })
  },
  success({ dispatch }, msg) {
    dispatch('show', { msg, type: 'success', icon: 'tl-check-circle' })
  },
  error({ dispatch }, msg) {
    dispatch('show', { msg, type: 'error', icon: 'tl-warning' })
  },
  show({ getters, commit, dispatch }, options = {}) {
    if (getters.active) {
      commit('hide')
      Vue.nextTick(() => dispatch('show', options))
    } else {
      options.icon = options.icon || 'tl-info'
      options.type = options.type || ''
      commit('show', options)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
