<template>
  <v-snackbar v-model="active" :color="type" bottom left @click="close">
    <v-icon v-if="icon.length > 0" dark left class="pr-3">{{ icon }}</v-icon>
    {{ msg }}
    <v-btn text icon @click.native="close"><v-icon>tl-close</v-icon></v-btn>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('toast', ['msg', 'type', 'icon']),
    active: {
      get() {
        return this.$store.getters['toast/active']
      },
      set() {
        this.close()
      },
    },
  },
  methods: {
    close() {
      this.$store.commit('toast/hide')
    },
  },
}
</script>
