import AutosavePlugin from '@/services/autosave'
import store from '@/store'

const autosavePluginConfig = {
  saveableFilter: record => record.$changed() || record.$isNew(),
  save: record =>
    record.$apiSave({
      onNetworkError: () => {
        store.dispatch('toolbar/setBannerOffline', true)
      },
      onBeforeSuccess: () => {
        store.dispatch('toolbar/setBannerOffline', false)
      },
    }),
  onError: (error, record) => {
    if (!error.response) return // No response = No internet connection. Don't restore, so it will keep trying
    record.$restore()
  },
}

const autosavePlugin = AutosavePlugin.install(autosavePluginConfig)
autosavePlugin(store)
