<template>
  <div>
    <v-list-item v-for="(item, i) in items" :key="i" :to="combineRoutes($route, item.to)" exact @click="collapseNav">
      <v-list-item-action v-if="item.icon">
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title v-text="$t(item.title)" />
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { combineRoutes } from '@/services/utils'

export default {
  props: {
    items: { type: Array, default: () => [] },
  },
  methods: {
    ...mapActions('nav', { collapseNav: 'collapse' }),
    combineRoutes,
  },
}
</script>

<style lang="sass">
[dark]
  color: white !important
</style>
