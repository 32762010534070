export default {
  method: 'GET',
  methodUrl: '',
  suffix: '',
  injectResponse: true, // Inject the API response into the database
  timeout: 10000,
  // Interceptors, called before resolving or rejecting the request promise:
  onInterceptRequest(config) {
    return config
  },
  onInterceptSuccess(response) {
    return response.data
  },
  onInterceptError(error) {
    return Promise.reject(error)
  },
  // Generic callback Functions:
  onRequest(config) { // eslint-disable-line
    return true // Return a falsy value to prevent sending the request.
  },
  onBeforeSuccess() {},
  onSucces(data) {
    return data
  },
  onBeforeError(error, config) {
    const { response } = error
    const errorTypes = {
      401: config.onUnauthorised,
      500: config.onServerError,
    }
    if (!response) {
      config.onNetworkError(error, config)
    } else if (response.status in errorTypes) {
      errorTypes[response.status](error, config)
    } else {
      config.onGenericError(error, config)
    }
  },
  onNetworkError(error, config) {
    return config.onGenericError(error, config)
  },
  onUnauthorised(error, config) {
    return config.onGenericError(error, config)
  },
  onServerError(error, config) {
    return config.onGenericError(error, config)
  },
  onGenericError(error, config) {}, // eslint-disable-line
  onError(error) {
    return Promise.reject(error)
  },
}
