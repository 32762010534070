import Model from '@/services/orm/Model'
import { parseDateSafe } from '@/services/parsers'

export default class Setter extends Model {
  static name = 'setters'
  static urlParent = 'gym'

  static relations = [
    Setter.belongsTo('users', { key: 'user_id', field: 'user' }),
    Setter.belongsTo('gyms', { key: 'gym_id', field: 'gym' }),
    Setter.hasMany('climbs', { key: 'setter_id' }),
  ]

  static parsers = {
    // id: this.increment(),
    // user_id: this.attr(null),
    // gym_id: this.attr(null),
    // email: this.string('').nullable(),
    // linked: this.boolean(false),
    // admin: this.boolean(false),
    // name: this.string('').nullable(),
    removed_at: parseDateSafe,
    // order: this.number('').nullable(),
  }
}
