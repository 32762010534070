import Vue from 'vue'
import VueGtag from 'vue-gtag'
import router from '@/router'

Vue.use(
  VueGtag,
  {
    config: { id: 'UA-92275546-1' },
  },
  router
)
