/* eslint-disable */
export default {
  key: 'ewbank',
  name: 'Ewbank',
  description: 'Australian numerical climbing grade system',
  types: ['route'],
  data: [
    { value: 2,    name: '7',  level: 0 },
    { value: 3,    name: '8',  level: 0, name0: '8-11', name1: '8-9' },
    { value: 3.33, name: '9',  level: 2 },
    { value: 3.67, name: '10', level: 1, name1: '10-11' },
    { value: 4,    name: '11', level: 2 },
    { value: 4.33, name: '12', level: 0, name0: '12-15', name1: '12-13' },
    { value: 4.66, name: '13', level: 2 },
    { value: 5,    name: '14', level: 1, name1: '14-15' },
    { value: 5.33, name: '15', level: 2 },
    { value: 5.67, name: '16', level: 0, name0: '16-19', name1: '16-17' },
    { value: 6,    name: '17', level: 2 },
    { value: 6.17, name: '18', level: 1, name1: '18-19' },
    { value: 6.33, name: '19', level: 2 },
    { value: 6.5,  name: '20', level: 0, name0: '20-23', name1: '20-21' },
    { value: 6.67, name: '21', level: 2 },
    { value: 6.83, name: '22', level: 1, name1: '22-23' },
    { value: 7,    name: '23', level: 2 },
    { value: 7.17, name: '24', level: 0, name0: '24-27', name1: '24-25' },
    { value: 7.33, name: '25', level: 2 },
    { value: 7.5,  name: '26', level: 1, name1: '26-27' },
    { value: 7.67, name: '27', level: 2 },
    { value: 7.83, name: '28', level: 0, name0: '28-31', name1: '28-29' },
    { value: 8,    name: '29', level: 2 },
    { value: 8.17, name: '30', level: 1, name1: '30-31' },
    { value: 8.33, name: '31', level: 2 },
    { value: 8.5,  name: '32', level: 0, name0: '32-35', name1: '32-33' },
    { value: 8.67, name: '33', level: 2 },
    { value: 8.83, name: '34', level: 1, name1: '34-35' },
    { value: 9,    name: '35', level: 2 },
    { value: 9.17, name: '36', level: 0, name0: '36-38', name1: '36-37' },
    { value: 9.33, name: '37', level: 2 },
    { value: 9.5,  name: '38', level: 1 },
  ]
}
