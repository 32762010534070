<template>
  <v-app-bar fixed app :dark="dark" v-resize="updateToolbarHeight">
    <v-app-bar-nav-icon v-if="btnLeft" @click="btnLeft.action($event)">
      <v-icon>{{ btnLeft.icon }}</v-icon>
    </v-app-bar-nav-icon>
    <v-app-bar-nav-icon v-else @click="toggleNav()">
      <v-icon v-if="isExpanded">tl-close</v-icon>
    </v-app-bar-nav-icon>

    <v-toolbar-title v-if="!showSearch">{{ title }}</v-toolbar-title>
    <v-text-field
      v-if="showSearch"
      v-model="search"
      ref="search"
      single-line
      hide-details
      prepend-icon="tl-search"
      :label="$t('generic.search')"
    />

    <v-spacer v-if="!showSearch" />

    <v-btn icon v-if="searchable" @click="toggleShowSearch">
      <v-icon>{{ showSearch ? 'tl-close' : 'tl-search' }}</v-icon>
    </v-btn>

    <component :is="rightComp" v-bind="rightCompProps" />

    <template v-for="btn in iconButtons">
      <v-badge :key="btn.text || btn.icon" color="primary" overlap class="tl-toolbar-btn-badge">
        <template v-if="btn.iconBadge" v-slot:badge>
          <v-icon dark>{{ btn.iconBadge }}</v-icon>
        </template>
        <v-btn
          :icon="isIconButton(btn)"
          text
          @click="btn.action($event)"
          :disabled="btn.disabled"
          :loading="btn.loading"
        >
          <v-icon :left="!isIconButton(btn)" size="24">{{ btn.icon }}</v-icon>
          <span v-if="!isIconButton(btn)">{{ btn.text }}</span>
        </v-btn>
      </v-badge>
    </template>

    <v-menu v-if="textButtons.length" left offset-y min-width="200">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>tl-more-vert</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="btn in textButtons"
          :key="btn.text"
          :disabled="btn.disabled"
          :loading="btn.loading"
          @click="btn.action($event)"
        >
          <v-list-item-title>{{ btn.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <template v-if="tabs.length" v-slot:extension>
      <v-tabs v-model="currentTabIndex" :grow="$vuetify.breakpoint.xs" background-color="transparent">
        <v-tab v-for="tab in tabs" :key="tab.name" :to="tab.to ? combineRoutes($route, tab.to) : null">
          {{ tab.name }}
        </v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { combineRoutes } from '@/services/utils'

export default {
  data: () => ({
    showSearch: false,
  }),
  computed: {
    ...mapState('toolbar', [
      'dark',
      'btnLeft',
      'title',
      'buttons',
      'searchable',
      'rightComp',
      'rightCompProps',
      'tabs',
      'currentTabName',
    ]),
    ...mapGetters('nav', ['isExpanded']),
    search: {
      get() {
        return this.$store.state.toolbar.search
      },
      set(newVal) {
        this.setSearch(newVal)
      },
    },
    visibleButtons() {
      return this.buttons.filter(btn => !btn.hidden)
    },
    iconButtons() {
      return this.visibleButtons.filter(btn => btn.icon)
    },
    textButtons() {
      return this.visibleButtons.filter(btn => !btn.icon)
    },
    isIconButton() {
      return btn => this.$vuetify.breakpoint.xsOnly || !btn.text
    },
    currentTabIndex: {
      get() {
        return this.tabs.map(t => t.name).indexOf(this.currentTabName)
      },
      set(index) {
        let tab = this.tabs[index]
        if (tab) this.setCurrentTabName(this.tabs[index].name)
      },
    },
  },
  watch: {
    searchable(enabled) {
      if (!enabled) this.showSearch = false
    },
  },
  methods: {
    ...mapMutations('nav', { toggleNav: 'toggle' }),
    ...mapMutations('toolbar', ['setCurrentTabName', 'setShowSearch', 'setSearch']),
    toggleShowSearch() {
      this.showSearch = !this.showSearch
      if (this.showSearch) {
        this.$nextTick(() => this.$refs.search.focus())
      } else {
        this.setSearch('')
      }
    },
    updateToolbarHeight() {
      document.documentElement.style.setProperty('--h-toolbar', `${this.$el.clientHeight}px`)
    },
    combineRoutes,
  },
}
</script>

<style lang="sass">
@include media-breakpoint('xs-only')
  .tl-toolbar-btn-badge .v-badge__badge
    top: 0
</style>
