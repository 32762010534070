import router from '@/router'

const PLATFORMS = ['android', 'ios']

const state = () => ({
  platform: localStorage.getItem('cordova_platform'),
  deviceReady: false,
  layoutMounted: false,
})

const getters = {
  isApp: state => state.platform !== null,
}

const mutations = {
  setPlatform(state, platform) {
    if (!PLATFORMS.includes(platform)) platform = null
    localStorage.setItem('cordova_platform', platform)
    state.platform = platform
  },
  setDeviceReady(state) {
    state.deviceReady = true
    if (state.layoutMounted) navigator.splashscreen && navigator.splashscreen.hide()
  },
  setLayoutMounted(state) {
    state.layoutMounted = true
    navigator.splashscreen && navigator.splashscreen.hide()
  },
}

const actions = {
  setPlatform({ commit, dispatch }, platform) {
    commit('setPlatform', platform)
    dispatch('init')
  },
  async init({ state, getters, commit, dispatch }) {
    if (!getters.isApp) return
    if (window.cordova) return
    let readyHandler = () => {
      commit('setDeviceReady')
      document.addEventListener('backbutton', () => dispatch('back'), false)
      document.addEventListener('offline', () => dispatch('toolbar/setBannerOffline', true, { root: true }), false)
      document.addEventListener('online', () => dispatch('toolbar/setBannerOffline', false, { root: true }), false)
    }
    document.addEventListener('deviceready', readyHandler, false)

    // Load cordova.js:
    let scriptTag = document.createElement('script')
    scriptTag.type = 'text/javascript'
    scriptTag.src = `/cordova/${state.platform}/cordova.js`
    document.body.appendChild(scriptTag)

    // If troubles persist, maybe manually trigger deviceReady
    // https://stackoverflow.com/a/6783497/3071369
    // window.setTimeout(() => {
    //   let e = document.createEvent('Events')
    //   e.initEvent('deviceready', true, false)
    //   document.dispatchEvent(e)
    // }, 50)
  },
  back({ rootState, dispatch, rootGetters }) {
    // 1. Cancel confirm/prompt dialog and other inPage routes
    if (rootState.dialog.visible) return dispatch('dialog/close', null, { root: true })
    if (router.currentRoute.meta.inPageRoute) return router.toBackRoute()
    // 2. Close sidenav right
    if (rootState['nav-right'].expanded) return dispatch('nav-right/collapse', null, { root: true })
    // 3. Close sidenav left
    if (rootGetters['nav/isExpanded']) return dispatch('nav/collapse', null, { root: true })
    // 4. Clear selection
    if (rootState.selection.selection.length) return dispatch('selection/clear', null, { root: true })
    // 5. Close app if no parentRoute
    if (router.currentRoute.name == 'auth.login' || (router.currentRoute.name == 'dashboard' && !rootState.user.guest))
      return dispatch('exitApp')
    // 6. Go to parent route
    router.toBackRoute()
  },
  exitApp({ state }) {
    if (state.isKiosk) return
    navigator.app.exitApp()
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
