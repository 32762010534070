import Container from './Container'

const defaultConfig = {
  namespace: 'database',
  modelParsers: {},
}

export default class Orm {
  constructor(store, database, config = {}) {
    this.config = Object.assign({}, defaultConfig, config)
    Container.register(database)
    database.addToStore({ store, namespace: this.config.namespace })
    // Register the generic modelParsers from the config on each model:
    database.models.forEach(model => {
      Object.assign(model.parsers, this.config.modelParsers)
    })
  }
}
