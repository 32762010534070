import Model from '@/services/orm/Model'
import moment from 'moment'

export default class Gym extends Model {
  static name = 'gyms'

  static indexes = ['slug']

  static apiActions = {
    findBySlug: {
      methodUrl: '/:slug',
      injectResponse: true,
    },
    climbRenewals: {
      methodUrl: '/:gym_id/new_old',
    },
    rankedUsers: {
      methodUrl: '/:gym_id/ranked_users',
    },
    feedbacks: {
      methodUrl: '/:gym_id/feedbacks',
    },
    popularityStats: {
      methodUrl: '/:gym_id/popularity_stats',
    },
    setterStats: {
      methodUrl: '/:gym_id/setter_stats',
    },
    activeUserCounts: {
      methodUrl: '/:gym_id/active_user_counts',
    },
  }

  static relations = [
    Gym.hasMany('users', { key: 'gym_id' }),
    Gym.hasMany('holds', { key: 'gym_id' }),
    Gym.hasMany('walls', { key: 'gym_id' }),
    Gym.hasMany('climbs', { key: 'gym_id' }),
    Gym.hasMany('groups', { key: 'gym_id' }),
    Gym.hasMany('setters', { key: 'gym_id' }),
    Gym.hasMany('regions', { key: 'gym_id' }),
    Gym.hasMany('comp_gyms', { key: 'gym_id' }),
    Gym.hasMany('feedbacks', { key: 'gym_id' }),
    Gym.hasMany('notifications', { key: 'gym_id' }),
    Gym.hasMany('gym_resources', { key: 'gym_id' }),
    Gym.hasMany('gym_notify_settings', { key: 'gym_id' }),
  ]

  static parsers = {
    latitude: parseFloat,
    longitude: parseFloat,
  }

  static getSrcImages = gym => {
    return `${process.env.VUE_APP_URL_CDN}/images/gyms/${gym.id_name}/`
  }

  get srcImages() {
    return Gym.getSrcImages(this)
  }
  get srcIcon() {
    return `${this.srcImages}logo_100x100.png?v=1`
  }
  get srcLogo() {
    return `${this.srcImages}logo_600x300.png`
  }
  get srcMainImpression() {
    const mainImpression = this.gym_resources.find(gr => gr.resource_type == 'main_impression')
    return mainImpression ? mainImpression.url : null
  }
  get srcFloorplan() {
    return `${this.srcImages}floorplan.svg?${this.floorplan_version}`
  }
  get climbTypes() {
    let types = []
    if (this.nr_of_routes) types.push('routes')
    if (this.nr_of_boulders) types.push('boulders')
    return types
  }

  // Some gym-dependent utility functions concerning climb dates:
  isNew(date_set) {
    if (!date_set) return false
    return moment()
      .subtract(this.label_new_days, 'day')
      .isBefore(moment(date_set))
  }
  removedSoon(date_removed) {
    if (!date_removed) return false
    return moment()
      .add(this.label_removed_days, 'day')
      .isAfter(moment(date_removed))
  }
  removedSooner(date_removed) {
    if (!date_removed) return false
    return moment()
      .add(Math.round(this.label_removed_days / 2), 'day')
      .isAfter(moment(date_removed))
  }
  removedExpired(date_removed) {
    if (!date_removed) return false
    return moment()
      .startOf('day')
      .isAfter(moment(date_removed))
  }
}
