import Vue from 'vue'
import Router from 'vue-router'
import routes from '@/components/routes'
import store from './store'
import i18n from '@/plugins/i18n'
import goTo from 'vuetify/es5/services/goto'
import { beforeEach as authGuard } from '@/components/auth/auth.guard'
import { combineRoutes } from '@/services/utils'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo, { container: 'main > .v-content__wrap' })
  },
})

// Smarter routing functions that merge the passed params
// into the current route's name, hash, params and query:
router.pushSmart = newRoute => {
  router.push(combineRoutes(router.currentRoute, newRoute))
}
router.replaceSmart = newRoute => {
  router.replace(combineRoutes(router.currentRoute, newRoute))
}
// Go to parent unless the current route has a meta backRoute
router.toBackRoute = () => {
  let currentRouteRecord = router.currentRoute.matched.slice(-1)[0]
  router.replaceSmart(currentRouteRecord.meta.backRoute || currentRouteRecord.parent)
}

// Prevent navigation when offline
router.beforeEach((to, from, next) => {
  if (!navigator.onLine) {
    store.dispatch('toast/error', i18n.t('generic.offlineMsg'))
    next(false)
  } else {
    next()
  }
})

// Assure there is always a user in the store defined:
router.beforeEach(async (to, from, next) => {
  let user = await store.dispatch('assureUser')
  if (user.gym && user.gym.slug) await store.dispatch('setGym', user.gym.slug)
  next()
})

router.beforeEach(authGuard)

router.afterEach((to, from) => {
  let matchedDifference = to.matched.filter(x => !from.matched.includes(x))
  if (matchedDifference.some(route => !route.meta.inPageRoute)) {
    store.dispatch('nav-right/clear')
    store.dispatch('selection/clear')
    store.dispatch('toolbar/reset')
    store.dispatch('autosave/setRecordsToSave', [])
  }
})

// Show banner guest if guest:
router.afterEach(to => {
  let noGuestBanner =
    !store.state.user.guest ||
    store.state.kiosk.isKiosk ||
    sessionStorage.getItem('hide_guest_banner') ||
    to.name.startsWith('auth')
  if (noGuestBanner) return

  store.commit('toolbar/setIdBanner', {
    id: 'guestBanner',
    icon: 'tl-account-circle',
    text: i18n.t('climbs.guestMsg'),
    btns: [
      {
        text: i18n.t('auth.login'),
        action: () => router.replaceSmart({ name: 'auth.login' }),
      },
      {
        icon: 'tl-close',
        action: () => {
          store.commit('toolbar/removeIdBanner', 'guestBanner')
          sessionStorage.setItem('hide_guest_banner', true)
        },
      },
    ],
  })
})

export default router
