import Model from '@/services/orm/Model'
import { parseDateSafe } from '@/services/parsers'

export default class Wall extends Model {
  static name = 'walls'
  static urlParent = 'gym'

  static relations = [
    Wall.belongsTo('gyms', { key: 'gym_id', field: 'gym' }),
    Wall.hasMany('climbs', { key: 'wall_id' }),
  ]

  static indexes = ['floorplan_id']

  static parsers = {
    removed_at: parseDateSafe,
  }
}
