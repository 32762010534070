export default class AutosaveModule {
  constructor(config) {
    AutosaveModule.config = config
    this.namespaced = true
    this.state = AutosaveModule.state
    this.getters = AutosaveModule.getters
    this.mutations = AutosaveModule.mutations
    this.actions = AutosaveModule.actions
  }

  static config = {}

  static state = {
    recordsToSave: [],
    saveDebounceTimer: null,
    saveDelay: 3000,
    saving: false,
  }

  static getters = {
    saveables(state) {
      return state.recordsToSave.filter(AutosaveModule.config.saveableFilter)
    },
    status(state, getters) {
      if (!getters.saveables.length) return 'saved'
      return state.saving ? 'saving' : 'saveable'
    },
  }

  static mutations = {
    setSaving(state, saving) {
      state.saving = !!saving
    },
    setRecordsToSave(state, records = []) {
      state.recordsToSave = [...records]
    },
    addRecordsToSave(state, records = []) {
      const newRecords = records.filter(r => !state.recordsToSave.includes(r))
      state.recordsToSave = state.recordsToSave.concat(newRecords)
    },
    removeRecordsToSave(state, records = []) {
      records.forEach(record => {
        let index = state.recordsToSave.indexOf(record)
        if (index >= 0) state.recordsToSave.splice(index, 1)
      })
    },
    setSaveDebounceTimer(state, callback) {
      if (process.server) return
      if (state.saveDebounceTimer) return
      state.saveDebounceTimer = window.setTimeout(callback, state.saveDelay)
    },
    clearSaveDebounceTimer(state) {
      if (process.server) return
      window.clearTimeout(state.saveDebounceTimer)
      state.saveDebounceTimer = null
    },
  }

  static actions = {
    setRecordsToSave({ state, commit, dispatch }, records) {
      const recordsToDel = state.recordsToSave.filter(r => !records.includes(r))
      dispatch('saveNow', recordsToDel) // Before setting, save all that are about to be deleted.
      commit('setRecordsToSave', records)
    },
    addRecordsToSave({ commit }, records) {
      commit('addRecordsToSave', records)
    },
    removeRecordsToSave({ state, commit, dispatch }, records) {
      const recordsToDel = state.recordsToSave.filter(r => records.includes(r))
      dispatch('saveNow', recordsToDel) // Before changing, save all unsaved immediatly.
      commit('removeRecordsToSave', recordsToDel)
    },
    saveNow({ state, commit }, toSave) {
      toSave = toSave || state.recordsToSave
      commit('clearSaveDebounceTimer')
      commit('setSaving', true)
      let savePromises = toSave.filter(AutosaveModule.config.saveableFilter).map(saveable => {
        return AutosaveModule.config.save(saveable).catch(error => {
          AutosaveModule.config.onError(error, saveable)
        })
      })
      return Promise.all(savePromises)
        .catch(() => {}) // Prevent unhandled promise rejection warnings
        .finally(() => commit('setSaving', false))
    },
    save({ commit, dispatch }) {
      commit('clearSaveDebounceTimer')
      commit('setSaveDebounceTimer', () => dispatch('saveNow'))
    },
  }
}
