import { capitalize } from '@/services/utils'
import store from '@/store'

// resp: {
//   data: {
//     errors: [ // Case 1
//       { message: 'Case 1a' }, // Case 1a
//       'Case 1b', // Case 1b
//     ],
//     errors: { // Case 2a
//       full_messages: ['Too short', 'Too long'],
//     },
//     errors: { // Case 2b
//       'email': ['No at sign', 'Too short'], // Case 2bI Array of strings
//       'password': 'Too short', // Case 2bII String
//     },
//     error: 'Not allowed', // Case 3
//   },
//   data: ['Case 4 fault 1', 'Case 4 fault 2'], // Case 4
// }

function showToast(error, { fallback = '' } = {}) {
  let resp = error.response || error // Axios creates an error object and adds the response as (hidden) property.
  let data = resp.data || resp || {}
  let msg = fallback

  try {
    // Axios fire's network errors: https://github.com/axios/axios/blob/master/lib/adapters/xhr.js#L81
    if (error instanceof Error && error.message == 'Network Error') {
      msg = 'No internet connection'
    } else if (data.errors) {
      if (data.errors instanceof Array) {
        // Case 1
        msg = data.errors.map(e => e.message || e).join(', ')
      } else if (data.errors.full_messages) {
        // Case 2a
        msg = data.errors.full_messages.join(', ')
      } else {
        // Case 2b
        for (let [key, err] of Object.entries(data.errors)) {
          if (err instanceof Array) {
            // Case 2bI
            msg = key + ': ' + err.join(', ')
          } else {
            // Case 2bII
            msg = key + ': ' + err
          }
        }
      }
    } else if (data.error) {
      // Case 3
      msg = data.error
    } else if (data instanceof Array) {
      // Case 4
      msg = data.join(', ')
    } else {
      throw 'Unsupported format'
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('TL Unsupported error format: ', resp)
  }

  store.dispatch('toast/error', capitalize(msg) || 'Something went wrong. Please try again later.')
  return Promise.reject(error)
}

export default {
  toast: showToast,
}
