import Model from '@/services/orm/Model'

export default class ClimbGroup extends Model {
  static name = 'climb_groups'

  static relations = [
    ClimbGroup.belongsTo('groups', { key: 'group_id', field: 'group' }),
    ClimbGroup.belongsTo('climbs', { key: 'climb_id', field: 'climb' }),
  ]

  static apiActions = {
    createMany: {
      methodUrl: '/create_many',
      method: 'POST',
    },
    destroyMany: {
      methodUrl: '/destroy_many',
      method: 'DELETE',
    },
  }
}
