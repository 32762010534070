import Model from '@/services/orm/Model'
import Climb from '@/models/Climb'
import Ascend from '@/models/Ascend'
import i18n from '@/plugins/i18n'

export default class User extends Model {
  static name = 'users'
  static apiActions = {
    signIn: {
      baseURL: process.env.VUE_APP_URL_API, // Authentication is not nested in the /v1 namespace.
      methodUrl: '/sign_in',
      method: 'POST',
    },
    signUp: {
      baseURL: process.env.VUE_APP_URL_API,
      method: 'POST',
    },
    signOut: {
      baseURL: process.env.VUE_APP_URL_API,
      methodUrl: '/sign_out',
      method: 'DELETE',
    },
    oauthGoogleCallback: {
      baseURL: process.env.VUE_APP_URL_API,
      methodUrl: '/auth/google_oauth2/callback',
      method: 'POST',
    },
    requestPwdReset: {
      baseURL: process.env.VUE_APP_URL_API,
      methodUrl: '/password',
      method: 'POST',
    },
    pwdReset: {
      baseURL: process.env.VUE_APP_URL_API,
      methodUrl: '/password',
      method: 'PUT',
    },
    stats: {
      methodUrl: '/:user_id/stats',
    },
    strengthHistory: {
      methodUrl: '/:user_id/strength_history',
    },
    feedbacks: {
      methodUrl: '/:user_id/feedbacks',
    },
    findWithAscendsCount: {
      methodUrl: '/find_with_ascends_count',
    },
  }
  static apiConfig = {
    onRequest(config) {
      // Prevent saving guests:
      return !(config.instance && config.instance.guest && config.method !== 'GET')
    },
  }

  static relations = [
    User.belongsTo('gyms', { key: 'gym_id', field: 'gym' }),
    User.hasMany('ascends', { key: 'user_id' }),
    User.hasMany('opinions', { key: 'user_id' }),
    User.hasMany('setters', { key: 'user_id' }),
    User.hasMany('feedbacks', { key: 'user_id' }),
    User.hasMany('notifications', { key: 'user_id' }),
    User.hasMany('comp_users', { key: 'user_id', field: 'group_users' }),
    User.hasMany('gym_notify_settings', { key: 'user_id' }),
  ]

  static indexes = ['uid']

  static async findOrCreateGuest() {
    let user = User.all().find(user => user.guest)
    if (!user) {
      user = await User.inject({ guest: true })
    }
    return user
  }

  // The key under which the user state is saved in localStorage
  get storageStateKey() {
    return `user_state_${this.uid || 'guest'}`
  }

  get storageState() {
    let storageState = JSON.parse(localStorage.getItem(this.storageStateKey))
    if (!storageState) {
      storageState = {
        gymSlug: null,
        climbType: null,
      }
      localStorage.setItem(this.storageStateKey, JSON.stringify(storageState))
    }
    return storageState
  }

  setStorageStateProp(prop, val) {
    let newState = Object.assign(this.storageState, { [prop]: val })
    localStorage.setItem(this.storageStateKey, JSON.stringify(newState))
  }

  // Temp getters until the backend has been rewritten and models named properly
  get comp_users() {
    return this.group_users
  }

  get fullName() {
    if (this.guest) return i18n.t('dashboard.guestUser')
    return [this.first_name, this.last_name].filter(str => str && str.length).join(' ') || i18n.t('sidenav.withoutName')
  }
  get setter() {
    return this.setters.find(setter => setter.gym_id == this.gym_id)
  }
  get isSetter() {
    return !!this.admin || !!this.setter
  }
  get isAdminSetter() {
    return !!this.admin || (!!this.setter && this.setter.admin)
  }

  async addRepeat(climbs) {
    climbs = [].concat(climbs) // Assure array
    let ascends = await Promise.all(
      climbs.map(climb => {
        return Ascend.inject({ user_id: this.id, climb_id: climb.id })
      })
    )
    return Ascend.checkMany(ascends, Infinity, 1)
  }

  async removeRepeat(climbs) {
    climbs = [].concat(climbs) // Assure array
    let ascends = climbs.map(climb => climb.lastAscend(Infinity)).filter(Boolean)
    return Ascend.uncheckMany(ascends)
  }

  async toggleCheck(climbs, zone = Infinity, checks = 1) {
    climbs = [].concat(climbs) // Assure array
    let ascends = await Promise.all(
      climbs.map(
        climb =>
          climb.firstAscend(zone) || // eslint-disable-line
          Ascend.inject({ user_id: this.id, climb_id: climb.id })
      )
    )
    let commonChecks = zone == Infinity ? Climb.getCommonChecks(climbs, { exact: true }) : ascends[0].checks
    let method = commonChecks == checks ? 'uncheckMany' : 'checkMany'
    return Ascend[method](ascends, zone, checks)
  }
}
