import axios from 'axios'
import OrmAxiosConfig from './OrmAxiosConfig'
import ApiModel from './ApiModel'
import ApiModelModule from './ApiModelModule'

export default class OrmAxios {
  constructor(components, config = {}) {
    this.components = components
    this.config = Object.assign({}, OrmAxiosConfig, config)
    this.axios = config.axios || axios.create() // Enable to provide axios yourself, so we can mock it in tests
    this.axios.interceptors.request.use(config => this.config.onInterceptRequest(config))
    this.axios.interceptors.response.use(
      response => this.config.onInterceptSuccess(response),
      error => this.config.onInterceptError(error)
    )
    this.extendComponents()
  }

  findModel(name) {
    return this.components.Container.database.findModel(name)
  }

  extendComponents() {
    // Extend the models:
    Object.assign(this.components.Model.prototype, ApiModel.instanceProps)
    Object.assign(this.components.Model.constructor.prototype, ApiModel.classProps)
    // Extend the modules:
    Object.assign(this.components.ModelModule.getters, ApiModelModule.getters)
    Object.assign(this.components.ModelModule.actions, ApiModelModule.actions)
  }

  static install(components, config) {
    this.instance = new OrmAxios(components, config)
    return this.instance
  }

  static getInstance() {
    return this.instance
  }
}
