import Vue from 'vue'
import i18n from '@/plugins/i18n'

const state = () => ({
  dark: false,
  searchable: false,
  search: '',
  btnLeft: null,
  title: '',
  buttons: [],
  rightComp: null,
  rightCompProps: {},
  tabs: [],
  currentTabName: null,
  banners: [],
  idBanners: [],
})

const getters = {
  banners(state) {
    return state.idBanners.concat(state.banners)
  },
}

const mutations = {
  setDark(state, newVal) {
    state.dark = !!newVal
  },
  setSearchable(state, newVal) {
    state.searchable = !!newVal
    state.search = ''
  },
  setSearch(state, newVal) {
    state.search = newVal || ''
  },
  setBtnLeft(state, btn) {
    state.btnLeft = btn || null
  },
  setTitle(state, title) {
    state.title = title || ''
  },
  setButtons(state, buttons) {
    state.buttons = buttons || []
  },
  setRightComp(state, { component, props } = {}) {
    state.rightComp = component || null
    state.rightCompProps = props || {}
  },
  setTabs(state, newTabs) {
    state.tabs = newTabs || []
  },
  setCurrentTabName(state, currentTabName) {
    state.currentTabName = currentTabName || null
  },
  setBanners(state, banners) {
    state.banners = banners || []
  },
  setIdBanner(state, banner) {
    if (!banner.id) throw new Error('TL: No banner id passed.')
    let index = state.idBanners.findIndex(b => b.id == banner.id)
    if (index != -1) {
      Vue.set(state.idBanners, index, banner)
    } else {
      state.idBanners.push(banner)
    }
  },
  removeIdBanner(state, bannerId) {
    let index = state.idBanners.findIndex(b => b.id == bannerId)
    if (index != -1) state.idBanners.splice(index, 1)
  },
  clearIdBanners(state) {
    state.idBanners = []
  },
}

const actions = {
  reset({ commit }) {
    commit('setDark', false)
    commit('setSearchable', false)
    commit('setBtnLeft')
    commit('setTitle')
    commit('setButtons')
    commit('setRightComp')
    commit('setTabs')
    commit('setCurrentTabName')
    commit('setBanners')
    commit('clearIdBanners')
  },
  setBannerOffline({ rootState, commit }, show) {
    let text = i18n.t('generic.offlineMsg')
    if (!rootState.user.guest) text = text + ' ' + i18n.t('generic.offlineMsgSave')
    const offlineBanner = {
      id: 'offline',
      icon: 'tl-wifi-strength-alert-outline',
      text,
    }
    show ? commit('setIdBanner', offlineBanner) : commit('removeIdBanner', 'offline')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
