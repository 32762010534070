export default [
  {
    path: 'setup',
    name: 'gymSetup',
    meta: { requiresAuth: 'isAdminSetter' },
    component: () => import(/* webpackChunkName: 'tl-admin-settings' */ './setup/tl-gym-setup.vue'),
  },
  {
    path: 'info',
    name: 'gymInfo',
    meta: { requiresAuth: 'isAdminSetter' },
    component: () => import(/* webpackChunkName: 'tl-admin-settings' */ './info/tl-gym-info.vue'),
  },
  {
    path: 'settings',
    name: 'gymSettings',
    meta: { requiresAuth: 'isAdminSetter' },
    component: () => import(/* webpackChunkName: 'tl-admin-settings' */ './settings/tl-gym-settings.vue'),
  },
]
