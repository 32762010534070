import i18n from '@/plugins/i18n'
import Vuetify from '@/plugins/vuetify'
import Vue from 'vue'

const state = {
  localeDefs: [
    { code: 'en-us', currency: 'USD', name: 'English - United States' },
    { code: 'en-gb', fallback: 'en-us', currency: 'GBP', name: 'English - United Kingdom' },
    { code: 'en-au', fallback: 'en-us', currency: 'AUD', name: 'English - Australia' },
    { code: 'es', currency: 'EUR', name: 'Español' },
    { code: 'nl', currency: 'EUR', name: 'Nederlands' },
    { code: 'de', currency: 'EUR', name: 'Deutsch' },
    { code: 'fr', currency: 'EUR', name: 'Français' },
  ],
  locale: 'en-us',
  fallbackLocale: 'en-us',
}

const mutations = {
  setLocale(state, locale) {
    i18n.locale = locale
    state.locale = locale
    localStorage.locale = locale
    Vue.prototype.moment.locale(locale)
    let lang = locale.substring(0, 2)
    Vuetify.framework.lang.current = lang // Used for date picker
    document.querySelector('html').setAttribute('lang', lang)
  },
  setLocaleFallback(state, fallbackLocale) {
    i18n.fallbackLocale = fallbackLocale
    state.fallbackLocale = fallbackLocale
  },
}

const getters = {
  localeCodes: state => state.localeDefs.map(l => l.code),
  validLocale: (state, getters) => locale => getters.localeCodes.find(code => code == locale),
}

const actions = {
  detectLocale({ rootState, getters }) {
    // 1. Priority on user-stored locale, then localStorage:
    let locales = [rootState.user.locale, localStorage.locale]

    // 2. Support for HTML 5.1 "navigator.languages":
    if (Array.isArray(navigator.languages)) {
      locales = locales.concat(navigator.languages)
    }

    // 3. Support for other well known properties in browsers:
    const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage']
    browserLanguagePropertyKeys.forEach(prop => locales.push(navigator[prop]))

    // Normalize the props: make lowercase with dashes and weighting factors (;q=0.9) removed.
    locales = locales.map(
      locale =>
        (locale || '')
          .split('_')
          .join('-')
          .toLowerCase()
          .split(';')[0]
    )

    let foundLocale = locales.find(getters.validLocale)
    if (!foundLocale) {
      // Fallback try with the country stripped, so just the lang:
      let localesStripped = locales.map(lang => lang.split('-')[0])
      foundLocale = localesStripped.find(getters.validLocale)
    }
    return foundLocale || i18n.fallbackLocale
  },
  setLocale({ commit }, locale) {
    let localeDef = state.localeDefs.find(def => def.code == locale)
    if (!localeDef) return // Invalid locale

    commit('setLocale', localeDef.code)
    if (localeDef.fallback) {
      commit('setLocaleFallback', localeDef.fallback)
    }
  },
  importLocales({ dispatch }) {
    let dfds = [dispatch('importLocale', i18n.locale)]
    if (i18n.fallbackLocale != i18n.locale) {
      dfds.push(dispatch('importLocale', i18n.fallbackLocale))
    }
    return Promise.all([dfds])
  },
  async importLocale(undefined, locale) {
    if (i18n.availableLocales.includes(locale)) return // Locale already loaded
    let m = await import(/* webpackChunkName: "locale-[request]" */ `@/assets/locales/${locale}`)
    i18n.setLocaleMessage(locale, m.default)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
