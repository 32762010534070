// Capitalizes first letter.
export function capitalize(str) {
  if (!str) return ''
  str = str.toString()
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// Convert CamelCase to kebap-case.
export function camelToKebab(str) {
  if (!str) return ''
  str = str.toString()
  return str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()
}

// Convert kebap-case or lower_kebap_case to CamelCase.
export function kebapToCamel(str) {
  if (!str) return ''
  str = str.toString()
  return str.replace(/[-_]([a-z])/g, g => g[1].toUpperCase())
}

// Pluralize unless count is 1
export function pluralize(word, count) {
  if (word.endsWith('s')) word = word.slice(0, -1)
  return count != 1 ? `${word}s` : word
}

// Singularize unless count > 1
export function singularize(word, count) {
  if (word.endsWith('s')) word = word.slice(0, -1)
  return count > 1 ? `${word}s` : word
}

// Math.round, but with precision. Defaults to 0 decimals.
export function round(value, precision) {
  let multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

// Is like new Date(), but then rounded to seconds. Prevents dirty orm objects as the server returns in seconds.
export function nowRoundedToSec() {
  return new Date(1000 * Math.floor(Date.now() / 1000))
}

// Math.min for arrays, with customizable fallback for empty arrays
export function arrayMin(arr, fallback = Infinity) {
  if (!arr.length) return fallback
  return Math.min.apply(null, arr)
}

// Math.max for arrays, with customizable fallback for empty arrays
export function arrayMax(arr, fallback = -Infinity) {
  if (!arr.length) return fallback
  return Math.max.apply(null, arr)
}

// Creates a route object copy with attributes of 'update' merged
export function combineRoutes(original, update) {
  return {
    name: update.name || original.name,
    hash: update.hash || original.hash,
    query: { ...original.query, ...update.query },
    params: { ...original.params, ...update.params },
    replace: update.replace || original.replace,
  }
}

/*
 * Helper function that generates a computed prop for two way store binding.
 * Usage:
 *
 * computed: {
 *   ...mapStateTwoWay({ mail: 'setMail' }),
 *   ...mapStateTwoWay('nested/namespace', { mail: 'setMail' }),
 * }
 *
 * Based on: https://stackoverflow.com/a/47305183/3071369
 */
export function mapStateTwoWay(...args) {
  const result = {}

  if (args.length === 1) {
    for (const prop of Object.keys(args[0])) {
      result[prop] = {
        get() {
          return this.$store.state[prop]
        },
        set(value) {
          this.$store.commit(args[0][prop], value)
        },
      }
    }
  } else {
    for (const prop of Object.keys(args[1])) {
      result[prop] = {
        get() {
          let stateNs = this.$store.state
          args[0].split('/').forEach(nsComp => (stateNs = stateNs[nsComp]))
          return stateNs[prop]
        },
        set(value) {
          this.$store.commit(args[0] + '/' + args[1][prop], value)
        },
      }
    }
  }

  return result
}

// Little function to get all properties and methods (including inherited) of an object.
export function logObj(obj) {
  let logStr = ''
  let indentation = ''
  while ((obj = Reflect.getPrototypeOf(obj)) && obj.constructor.name != 'Object') {
    logStr = `${logStr}\n${indentation}${obj.constructor.name}`
    indentation = indentation + '  '
    Reflect.ownKeys(obj).forEach(key => (logStr = `${logStr}\n${indentation}- ${key}`))
  }
  // eslint-disable-next-line no-console
  console.log(logStr)
}

function hexToRGB(color = '000000') {
  let hexcolor = color.replace('#', '')
  // If only first half of color is defined, repeat it:
  if (hexcolor.length < 5) {
    hexcolor += hexcolor.slice(1)
  }

  return {
    r: parseInt(hexcolor.substr(0, 2), 16),
    g: parseInt(hexcolor.substr(2, 2), 16),
    b: parseInt(hexcolor.substr(4, 2), 16),
  }
}

// Returns the most contrasting of black and white given a background-color. https://24ways.org/2010/calculating-color-contrast/
export function contrastingColor(color) {
  let rgb = hexToRGB(color)
  let yiq = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000
  return yiq >= 128 ? 'black' : 'white'
}

export function hexColorDelta(hex1, hex2) {
  let rgb1 = hexToRGB(hex1)
  let rgb2 = hexToRGB(hex2)
  let dr = (255 - Math.abs(rgb1.r - rgb2.r)) / 255
  let dg = (255 - Math.abs(rgb1.g - rgb2.g)) / 255
  let db = (255 - Math.abs(rgb1.b - rgb2.b)) / 255
  return 1 - (dr + dg + db) / 3
}
