import Model from '@/services/orm/Model'
import { parseDateSafe } from '@/services/parsers'

export default class Hold extends Model {
  static name = 'holds'
  static urlParent = 'gym'

  static relations = [
    Hold.belongsTo('gyms', { key: 'gym_id', field: 'gym' }),
    Hold.hasMany('climbs', { key: 'hold_id' }),
    Hold.hasMany('groups', { key: 'hold_id' }),
  ]

  static parsers = {
    // id: this.increment(),
    // gym_id: this.attr(null),
    // brand: this.string(null).nullable(),
    // color: this.string(null).nullable(),
    // color_secondary: this.string(null).nullable(),
    removed_at: parseDateSafe,
    // order: this.number('').nullable(),
  }

  get name() {
    return this.brand
  }
}
