export default [
  {
    path: 'reservations',
    name: 'reservations',
    redirect: { name: 'bookings.book' },
  },
  {
    path: 'bookings',
    name: 'bookings',
    redirect: { name: 'bookings.book' },
    component: () => import(/* webpackChunkName: 'tl-reservations' */ './tl-reservations-route.vue'),
    children: [
      {
        path: 'book',
        name: 'bookings.book',
        component: () => import(/* webpackChunkName: 'tl-reservations' */ './tl-reservations.vue'),
        meta: { inPageRoute: true },
      },
      {
        path: 'my-bookings',
        name: 'bookings.my-bookings',
        component: () => import(/* webpackChunkName: 'tl-reservations' */ './tl-my-reservations.vue'),
        meta: { inPageRoute: true },
      },
    ],
  },
]
