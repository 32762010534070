import Model from '@/services/orm/Model'
import { parseDateSafe, parseFloatSafe } from '@/services/parsers'

export default class Notification extends Model {
  static name = 'notifications'
  static urlParent = 'gym'

  static relations = [
    Notification.belongsTo('gyms', { key: 'gym_id', field: 'gym' }),
    Notification.belongsTo('users', { key: 'user_id', field: 'user' }),
  ]

  static parsers = {
    process_at: parseDateSafe,
    processed_at: parseDateSafe,
    grade_min: parseFloatSafe,
    grade_max: parseFloatSafe,
  }

  static tags = {
    route: { label: 'New routes' },
    boulder: { label: 'New boulders' },
    news: { label: 'News' },
    competitions: { label: 'Competition news' },
  }

  async isProcessed() {
    let notification = await Notification.$apiFind(this.id, {
      injectResponse: false,
      params: {
        gym_id: this.gym_id,
      },
    })
    if (notification.processed_at) {
      Notification.inject(notification)
      this.$purgeChanges()
    }
    return !!notification.processed_at
  }
}
