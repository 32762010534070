import store from '@/store'
import { combineRoutes } from '@/services/utils'

export default async (to, from, next) => {
  let slug = to.params.gymSlug

  // Redirect if no valid slug was passed
  if (!slug) {
    let storageSlug = store.state.user.storageState.gymSlug
    let userGymSlug = store.state.user.gym && store.state.user.gym.slug
    let redirectSlug = storageSlug || userGymSlug
    if (!redirectSlug) return next({ name: 'gyms' }) // No gym was chosen yet
    return next(combineRoutes(to, { params: { gymSlug: redirectSlug }, replace: true }))
  }

  let gym = await store.dispatch('setGym', slug)
  gym ? next() : next({ name: 'gyms' })
}
