// Search for /:paramName in the url and replaces it with values passed as params:
export function substituteParams(endpoint, params) {
  let urlParams = (endpoint.match(/(\/?)(:)([A-z]*)/gm) || []).map(param => param.replace('/', ''))
  urlParams.forEach(urlParam => {
    let paramName = urlParam.replace(':', '')
    if (!(paramName in (params || {}))) {
      throw new Error(`Parameter ${paramName} was not found in the params: ${JSON.stringify(params)}`)
    }
    // Assign the value in the url and remove from the params it so we won't send it twice.
    const paramValue = params[paramName]
    delete params[paramName]
    endpoint = endpoint.replace(urlParam, paramValue).replace('//', '/')
  })
  return endpoint
}

// Search the send data for props with value null or undefined and,
// if the server did not return a value for them,
// re-apply them to the received data to be injected:
export function addNullValues({ sent, received }) {
  if (!sent) return
  Object.keys(sent).forEach(prop => {
    if (sent[prop] == null && received[prop] === undefined) {
      received[prop] = sent[prop]
    }
  })
}
