import IndexModule from './IndexModule'

export default class IndexesModule {
  constructor(relations, indexes) {
    this.namespaced = true
    this.state = {}
    this.modules = this.createModules(relations, indexes)
    this.actions = this.createActions()
  }

  createModules(relations, indexes) {
    // Create an index for each relation:
    const modules = relations.reduce((indexes, relation) => {
      if (relation.foreignKey) {
        indexes[relation.foreignKey] = new IndexModule()
      }
      return indexes
    }, {})

    // Add custom indexes:
    indexes.forEach(index => {
      modules[index] = new IndexModule()
    })

    return modules
  }

  createActions() {
    return {
      inject: ({ dispatch }, instance) => {
        Object.keys(this.modules).forEach(index => {
          dispatch(`${index}/add`, { foreignId: instance[index], obj: instance })
        })
      },
      update: ({ dispatch }, { instance, changes }) => {
        // If the key of any of the changed props is that of the index (so a foreignKey), it should be updated
        Object.keys(this.modules).forEach(index => {
          if (typeof changes[index] !== 'undefined') {
            dispatch(`${index}/remove`, { foreignId: instance[index], obj: instance })
            dispatch(`${index}/add`, { foreignId: changes[index], obj: instance })
          }
        })
      },
      eject: ({ dispatch }, instance) => {
        Object.keys(this.modules).forEach(index => {
          dispatch(`${index}/remove`, { foreignId: instance[index], obj: instance })
        })
      },
    }
  }
}
