<template>
  <router-view />
</template>

<script>
export default {
  head: {
    title: 'TopLogger | The indoor climbing app',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: `TopLogger: an app to log your indoor climbs and boulders, check progress
        and compete with friends. The gym climbing assistant on Android and iOS.`,
      },
      { name: 'keywords', content: 'app, indoor climbing, bouldering, climbing gym, logging, ticking, gym software' },
      { name: 'robots', content: 'index, follow' }, // TODO make this env dependent
      { property: 'og:title', content: 'TopLogger' },
      { property: 'og:type', content: 'website' },
      { property: 'og:image', content: process.env.VUE_APP_URL_CDN + '/images/toplogger_icon.png' },
      { property: 'og:site_name', content: 'TopLogger' },
    ],
  },
}
</script>

<style lang="sass">
@import 'src/assets/style/main.sass'
</style>
