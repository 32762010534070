import Model from '@/services/orm/Model'

export default class GymNotifySetting extends Model {
  static name = 'gym_notify_settings'
  static urlParent = 'user'

  static relations = [
    GymNotifySetting.belongsTo('users', { key: 'user_id', field: 'user' }),
    GymNotifySetting.belongsTo('gyms', { key: 'gym_id', field: 'gym' }),
  ]
}
