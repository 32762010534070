<template>
  <v-navigation-drawer v-model="expanded" class="tl-nav" :mini-variant="mini" fixed app>
    <tl-nav-toolbar />
    <v-list class="tl-nav__profile" dark flat tile>
      <v-list-group v-model="userExpanded" class="tl-nav__head">
        <template v-slot:activator>
          <v-layout column class="py-2">
            <v-avatar :size="iconSize">
              <v-img
                v-if="user.avatar && user.avatar != avatarError"
                :src="user.avatar"
                :alt="$t('sidenav.profilePic')"
                @error="avatarError = user.avatar + ''"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey darken-3"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-icon v-else :size="iconSize">tl-person</v-icon>
            </v-avatar>
            <v-list-item-content class="pt-2 align-self-start">
              <v-expand-transition>
                <div v-show="!mini">
                  <v-list-item-title class="tl-nav__user-name align-self-start" v-text="user.fullName" />
                  <v-expand-transition>
                    <v-list-item-subtitle v-if="userExpanded" class="tl-nav__user-email">
                      {{ user.email }}
                    </v-list-item-subtitle>
                  </v-expand-transition>
                  <v-list-item-subtitle v-if="gym" class="text--primary">
                    {{ $t('sidenav.atGym', { gymName: gym.name }) }}
                  </v-list-item-subtitle>
                </div>
              </v-expand-transition>
            </v-list-item-content>
          </v-layout>
        </template>
        <tl-nav-list :items="userPages" />
      </v-list-group>
    </v-list>
    <v-list class="primary--text">
      <tl-nav-list :items="gymPages" />
    </v-list>
    <v-list v-if="adminPages.length" dark flat tile subheader class="tl-nav__admin-group">
      <v-expand-transition>
        <v-subheader v-show="!mini">Management</v-subheader>
      </v-expand-transition>
      <tl-nav-list :items="adminPages" />
    </v-list>
    <v-expand-transition>
      <v-list v-show="!mini" flat class="primary--text">
        <tl-nav-list :items="footerPages" />
      </v-list>
    </v-expand-transition>
    <v-list v-if="mini" flat>
      <v-list-item @click="toggleSidenav()">
        <v-list-item-avatar>
          <v-icon>tl-more-vert</v-icon>
        </v-list-item-avatar>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import tlNavToolbar from './tl-nav-toolbar.vue'
import tlNavList from './tl-nav-list.vue'
import { mapState, mapGetters, mapActions } from 'vuex'

function navItemFilter(item) {
  return item.show == undefined || item.show
}

export default {
  components: {
    tlNavToolbar,
    tlNavList,
  },
  data() {
    return {
      userExpandedState: false,
      adminExpanded: true,
      avatarError: null,
    }
  },
  computed: {
    ...mapState(['user', 'gym', 'climbType']),
    ...mapState('nav', ['mini']),
    ...mapState('kiosk', ['isKiosk']),
    ...mapGetters('auth', ['isAuth']),
    ...mapGetters('kiosk', ['isKioskLocked', 'isKioskUnlocked']),
    ...mapGetters('cordova', ['isApp']),
    iconSize() {
      return this.mini ? 48 : 64
    },
    userPages() {
      return [
        {
          to: { name: 'profile' },
          show: !!this.gym && !this.isKioskUnlocked,
          icon: 'tl-person',
          title: 'sidenav.myProfile',
        },
        { to: { name: 'gyms' }, icon: 'tl-swap-horiz', title: this.gym ? 'sidenav.switchGym' : 'sidenav.selectGym' },
        { to: { name: 'auth.login' }, show: !this.isAuth && !this.isKiosk, icon: 'tl-lock', title: 'sidenav.signIn' },
        {
          to: { name: 'auth.logout' },
          show: this.isAuth || this.isKioskLocked,
          icon: 'tl-exit-to-app',
          title: 'sidenav.signOut',
        },
      ].filter(navItemFilter)
    },
    gymPages() {
      if (!this.gym || this.isKioskUnlocked) return []
      return [
        {
          to: { name: 'climbs', params: { climbsType: 'routes' } },
          show: this.gym['nr_of_routes'] > 0,
          icon: this.gym.climbTypes.length > 1 ? 'tl-check-route' : 'tl-check',
          title: 'sidenav.routes',
        },
        {
          to: { name: 'climbs', params: { climbsType: 'boulders' } },
          show: this.gym['nr_of_boulders'] > 0,
          icon: this.gym.climbTypes.length > 1 ? 'tl-check-boulder' : 'tl-check',
          title: 'sidenav.boulders',
        },
        {
          to: { name: 'dashboard', params: { climbsType: this.climbType ? `${this.climbType}s` : 'climbs' } },
          icon: 'tl-timeline',
          title: 'sidenav.dashboard',
        },
        { to: { name: 'comps' }, icon: 'tl-trophy', title: 'sidenav.competitions' },
        { to: { name: 'myGym' }, icon: 'tl-directions', title: 'sidenav.myGym' },
        {
          to: { name: 'bookings.book' },
          show: this.gym.reservations_enabled,
          icon: 'tl-calendar-clock',
          title: 'sidenav.reservations',
        },
      ].filter(navItemFilter)
    },
    adminPages() {
      if (!this.gym || !this.user.isSetter) return []
      return [
        {
          to: { name: 'editClimbs', params: { climbsType: 'routes' } },
          show: this.gym.gym_type == 'climbing',
          icon: this.gym.gym_type == 'climbing' ? 'tl-edit-route' : 'tl-edit',
          title: 'sidenav.editRoutes',
        },
        {
          to: { name: 'editClimbs', params: { climbsType: 'boulders' } },
          icon: this.gym.gym_type == 'climbing' ? 'tl-edit-boulder' : 'tl-edit',
          title: 'sidenav.editBoulders',
        },
        { to: { name: 'admin.comps' }, icon: 'tl-trophy-edit', title: 'sidenav.editCompetitions' },
        { to: { name: 'gymComm' }, icon: 'tl-forum', title: 'sidenav.communications' },
        {
          to: { name: 'admin.reservations' },
          show: this.gym.reservations_enabled,
          icon: 'tl-calendar-edit',
          title: 'sidenav.reservations',
        },
        {
          to: { name: 'gym.stats.climbs' },
          show: !!this.user.isAdminSetter,
          icon: 'tl-trending-up',
          title: 'sidenav.statistics',
        },
        { to: { name: 'gymSetup' }, show: !!this.user.isAdminSetter, icon: 'tl-settings', title: 'generic.settings' },
      ].filter(navItemFilter)
    },
    footerPages() {
      return [
        { to: { name: 'help' }, title: 'sidenav.help' },
        { to: { name: 'about' }, title: 'sidenav.aboutUs', show: !this.isApp },
        { to: { name: 'feedback' }, title: 'sidenav.sendFeedback' },
        { to: { name: 'terms' }, title: 'sidenav.toc' },
      ].filter(navItemFilter)
    },
    expanded: {
      get: function() {
        return this.$store.state.nav.expanded
      },
      set: function(newVal) {
        this.toggleSidenav(newVal)
      },
    },
    userExpanded: {
      get: function() {
        return this.userExpandedState
      },
      set: function(expand) {
        if (expand && this.mini) this.toggleSidenav()
        this.userExpandedState = expand
      },
    },
  },
  created() {
    let activePage = this.userPages.map(p => '/' + p.to).includes(this.$route.path)
    if (activePage || !this.gym || (this.user.guest && !this.isKiosk)) {
      this.userExpanded = true
    }
  },
  mounted() {
    this.$store.dispatch('nav/collapse') // Open mini-variant on large screens
  },
  methods: {
    ...mapActions({ toggleSidenav: 'nav/toggle' }),
  },
}
</script>

<style lang="sass">
.tl-nav
  .v-list
    padding: 0
    .v-list-item
      font-size: 16px !important
      font-weight: normal
  &.v-navigation-drawer--mini-variant .v-list-group__header__append-icon
      display: none
  &__profile
    background-color: var(--v-grey-darken3) !important
    border-radius: 0
  &__head .v-list-group__header
    align-items: flex-end
    background: url('./sidenav_profile_bg.png') center / 100% 100% white !important
    &:hover
      background-color: #f5f5f5 !important
    &__append-icon
      padding-top: 64px
    .v-icon.theme--dark
      color: var(--v-grey-darken1)
    .v-avatar
      transition: width 0.3s, height 0.3s
    .v-list-item
      height: inherit
      flex-direction: column
      align-items: start
  &__user-name
    font-size: 16px
    font-weight: bold
    color: black
  &__user-email
    color: var(--v-grey-darken1) !important
    font-size: 12px
  &__admin-group
    background-color: var(--v-grey-darken3) !important
    border-radius: 0
</style>
