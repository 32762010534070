import AutosaveModule from './AutosaveModule'

const defaultConfig = {
  namespace: 'autosave',
  saveableFilter: () => true,
  save: () => Promise.resolve(true),
  onError: () => {},
}

export default class Autosave {
  constructor(config = {}) {
    this.config = Object.assign({}, defaultConfig, config)
    this.store = config.store
    this.store.registerModule(this.config.namespace, new AutosaveModule(this.config))
    if (process.server) return
    this.startWatch()
    this.addSaveBeforeUnload()
  }

  startWatch() {
    this.store.watch(
      (state, getters) => getters[`${this.config.namespace}/status`],
      newVal => {
        if (newVal === 'saveable') this.store.dispatch(`${this.config.namespace}/save`)
      }
    )
  }

  addSaveBeforeUnload() {
    window.addEventListener('beforeunload', event => {
      if (this.store.getters[`${this.config.namespace}/status`] === 'saved') return null

      this.store.dispatch(`${this.config.namespace}/saveNow`)
      const msg = 'You have unsaved changes. Are you sure you want to navigate away from this page?'
      event.preventDefault()
      event.returnValue = msg // Chrome requires returnValue to be set.
      return msg
    })
  }
}
