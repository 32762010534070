import Database from './orm/Database'
import models from '@/models'

const database = new Database()
models.forEach(model => {
  database.register(model)
})

export default database

// TODO: Refactor this so the Orm class doesn't get the database but an array of models passed in.
// The registration of the models in the database from above should be managed there.
