import store from '@/store'
import { combineRoutes } from '@/services/utils'

export default async (to, from, next) => {
  let locale = to.params.locale
  let userLocale = store.state.user.locale
  let validLocale = store.getters['i18n/validLocale']

  // Locale invalid or there is a different userLocale:
  if (!validLocale(locale) || (validLocale(userLocale) && validLocale(userLocale) != locale)) {
    locale = await store.dispatch('i18n/detectLocale')
    return next(combineRoutes(to, { params: { locale }, replace: true }))
  }

  store.dispatch('i18n/setLocale', locale)

  // Assure all are imported. Immediately resolves if already loaded:
  await store.dispatch('i18n/importLocales')
  return next()
}
