import User from '@/models/User'
import i18n from '@/plugins/i18n'

const state = () => ({
  email: '',
  token: null,
  userId: null,
})

const getters = {
  isAuth: state => Boolean(state.token),
}

const mutations = {
  setLoggedIn(state, { email, token, user_id }) {
    // Persist the mail and token for automatic future setLoggedIns:
    localStorage.setItem('user_email', email)
    localStorage.setItem('user_token', token)
    localStorage.setItem('user_id', user_id)
    // Set logged in:
    state.email = email
    state.token = token
    state.userId = user_id
  },
  setLoggedOut(state) {
    // Prevent future automatic logins:
    localStorage.removeItem('user_email')
    localStorage.removeItem('user_token')
    localStorage.removeItem('user_id')
    // Set logged out:
    state.email = ''
    state.token = null
    state.userId = null
  },
}

const actions = {
  localStorageToState({ rootState, getters, commit }) {
    const email = localStorage.getItem('user_email')
    const token = localStorage.getItem('user_token')
    const user_id = localStorage.getItem('user_id')

    const shouldAuth = email && token && user_id && !rootState.kiosk.isKiosk
    const isAuth = getters['isAuth']
    if (shouldAuth && !isAuth) {
      commit('setLoggedIn', { email, token, user_id })
    } else if (!shouldAuth) {
      commit('setLoggedOut')
    }
  },
  login({ commit }, payload) {
    return User.$apiCall('signIn', { data: { user: payload } }).then(response => {
      commit('setLoggedIn', {
        email: payload.email,
        token: response.authentication_token,
        user_id: response.user_id,
      })
    })
  },
  logout({ commit, dispatch }) {
    return User.$apiCall('signOut').finally(() => {
      commit('setLoggedOut')
      dispatch('toast/success', i18n.t('auth.msgLoggedOut'), { root: true })
    })
  },
  signUp({ dispatch }, userProps) {
    return User.$apiCall('signUp', { data: { user: userProps } }).then(() => {
      dispatch('toast/success', i18n.t('auth.msgSentConfirm'), { root: true })
    })
  },
  oauthGoogleCallback({ commit }, idToken) {
    return User.$apiCall('oauthGoogleCallback', { data: { idToken } }).then(resp => {
      commit('setLoggedIn', {
        email: resp.response.email,
        token: resp.response.token,
        user_id: resp.response.user_id,
      })
    })
  },
  requestPwdReset({ dispatch }, userProps) {
    return User.$apiCall('requestPwdReset', { data: { user: userProps } }).then(() => {
      dispatch('toast/success', i18n.t('auth.msgSentReset'), { root: true })
    })
  },
  pwdReset({ dispatch }, userProps) {
    return User.$apiCall('pwdReset', { data: { user: userProps } }).then(() => {
      dispatch('toast/success', i18n.t('auth.msgPwdReset'), { root: true })
    })
  },
  changeEmail({ dispatch }, { userId, newMail }) {
    return User.find(userId)
      .$apiUpdate({ email: newMail })
      .then(() => dispatch('toast/success', i18n.t('auth.msgSentConfirmNew'), { root: true }))
  },
  updatePassword({ dispatch }, { userId, pwd, pwdConfirm }) {
    return User.find(userId)
      .$apiUpdate({ user: { password: pwd || '', password_confirmation: pwdConfirm || '' } })
      .then(() => dispatch('toast/success', i18n.t('auth.msgPwdUpdated'), { root: true }))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
