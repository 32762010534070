import Model from '@/services/orm/Model'

export default class CompClimb extends Model {
  static name = 'comp_climbs'
  static endpoint = 'climb_groups'

  static relations = [
    CompClimb.belongsTo('comps', { key: 'group_id', field: 'group' }),
    CompClimb.belongsTo('climbs', { key: 'climb_id', field: 'climb' }),
  ]

  static apiActions = {
    createMany: {
      methodUrl: '/create_many',
      method: 'POST',
    },
    destroyMany: {
      methodUrl: '/destroy_many',
      method: 'DELETE',
    },
  }

  // Temp getter until the backend has been rewritten and models named properly
  get comp() {
    return this.group
  }
}
