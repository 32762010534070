import Vue from 'vue'

export default class Index {
  constructor() {
    this.namespaced = true
    this.state = {}
    this.getters = this.createGetters()
    this.mutations = this.createMutations()
    this.actions = this.createActions()
  }

  createGetters() {
    return {
      // Array of all changes
      changeLog: state => ({ id, since }) => {
        if (!state[id]) return []
        state[id]
        if (since) {
          return state[id].filter(change => change.time >= since)
        } else {
          return state[id]
        }
      },
      // Object with as keys the props that actually changed, values are old and new values
      changesDetailed: (undefined, getters) => ({ id, since }) => {
        const changeLog = getters.changeLog({ id, since })
        return changeLog.reduce((changes, change) => {
          if (typeof changes[change.prop] == 'undefined') {
            changes[change.prop] = { oldVal: change.oldVal } // Add this prop if it didn't change or it's change was undone
          }
          changes[change.prop].newVal = change.newVal

          let oldVal = changes[change.prop].oldVal
          if (JSON.stringify(oldVal) == JSON.stringify(change.newVal)) {
            delete changes[change.prop] // Remove this prop if it changed back to it's original value
          }
          return changes
        }, {})
      },
      // Object with just the changed props and their new values:
      changes: (undefined, getters) => ({ id, since }) => {
        const changesDetailed = getters.changesDetailed({ id, since })
        return Object.keys(changesDetailed).reduce((changes, prop) => {
          changes[prop] = changesDetailed[prop].newVal
          return changes
        }, {})
      },
      // Boolean if there are any changed props
      changed: (undefined, getters) => ({ id, since }) => {
        return Object.keys(getters.changes({ id, since })).length > 0
      },
    }
  }

  createMutations() {
    return {
      add: (state, { id, prop, oldVal, newVal }) => {
        if (!(state[id] instanceof Array)) {
          Vue.set(state, id, [])
        }
        state[id].push({
          prop,
          oldVal,
          newVal,
          time: new Date().getTime(),
        })
      },
      updateId: (state, { oldId, newId }) => {
        Vue.set(state, newId, state[oldId])
        Vue.delete(state, oldId)
      },
      purge: (state, { id, until, since }) => {
        if (until) {
          state[id] = state[id].filter(change => change.time >= until)
        } else if (since) {
          state[id] = state[id].filter(change => change.time <= since)
        } else {
          Vue.delete(state, id)
        }
      },
    }
  }

  createActions() {
    return {
      update: ({ commit }, { instance, changes }) => {
        const id = instance.$id
        Object.keys(changes).forEach(prop => {
          const oldVal = instance[prop]
          const newVal = changes[prop]
          if (JSON.stringify(oldVal) == JSON.stringify(newVal)) return // Only add actual changes
          commit('add', { id, prop, oldVal, newVal })
        })
      },
      updateId: ({ state, commit }, { oldId, newId }) => {
        if (!(state[oldId] instanceof Array)) return
        commit('updateId', { oldId, newId })
      },
      purge: ({ state, commit }, { id, until, since }) => {
        if (!(state[id] instanceof Array)) return
        commit('purge', { id, until, since })
      },
    }
  }
}
