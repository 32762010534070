import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
import i18n from '@/plugins/i18n'

// Map internal Vuetify icon names to actual TopLogger icon names.
const tlIcons = {
  complete: 'tl-check',
  cancel: 'tl-cancel',
  close: 'tl-close',
  delete: 'tl-cancel',
  remove: 'tl-remove',
  clear: 'tl-clear',
  success: 'tl-check-circle',
  info: 'tl-info',
  warning: 'tl-priority-high',
  error: 'tl-warning',
  prev: 'tl-chevron-left',
  next: 'tl-chevron-right',
  checkboxOn: 'tl-check-box',
  checkboxOff: 'tl-check-box-outline-blank',
  checkboxIndeterminate: 'tl-indeterminate-check-box',
  delimiter: 'tl-fiber-manual-record',
  sort: 'tl-arrow-upward',
  expand: 'tl-keyboard-arrow-down',
  menu: 'tl-menu',
  minus: 'tl-remove',
  plus: 'tl-add',
  subgroup: 'tl-arrow-drop-down',
  dropdown: 'tl-arrow-drop-down',
  radioOn: 'tl-radio-button-checked',
  radioOff: 'tl-radio-button-unchecked',
  edit: 'tl-edit',
  unfold: 'tl-unfold-more',
  ratingEmpty: 'tl-star',
  ratingFull: 'tl-star',
}

let theme = {
  primary: '#FF0094',
  accent: '#0eff00',
  info: '#0D47A1',
  warning: '#ffb300',
  error: '#B71C1C',
  success: '#2E7D32',
  grey: colors.grey,
}

const opts = {
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  icons: {
    values: tlIcons,
  },
  theme: {
    dark: false,
    themes: {
      light: theme,
      dark: theme,
    },
    options: {
      customProperties: true,
    },
  },
}

Vue.use(Vuetify)
export default new Vuetify(opts)
