import Orm from './Orm'
import Container from './Container'
import Database from './Database'
import IndexesModule from './IndexesModule'
import IndexModule from './IndexModule'
import Model from './Model'
import ModelModule from './ModelModule'
import ModelsModule from './ModelsModule'
import Normalizer from './Normalizer'
import Relation from './Relation'

export default class OrmPlugin {
  static plugins = new Set()

  static install(database, config = {}) {
    return store => {
      // Create the orm instance:
      const orm = new Orm(store, database, config)
      // Initiate each plugin with the orm instance:
      this.plugins.forEach(plugin => {
        if (typeof plugin.init === 'function') {
          plugin.init(orm)
        }
      })
      return orm
    }
  }

  static use(plugin, config) {
    const components = {
      Container,
      Database,
      IndexesModule,
      IndexModule,
      Model,
      ModelModule,
      ModelsModule,
      Normalizer,
      Relation,
    }
    plugin.install(components, config)
    this.plugins.add(plugin)
  }
}
