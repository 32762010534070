import OrmAxios from './OrmAxios'
import { substituteParams, addNullValues } from './utils'

export default {
  getters: {
    model: state => OrmAxios.getInstance().findModel(state.$name),
    resourceUrl: (state, getters) => `/${getters.model.endpoint || getters.model.name}`,
    nestedResourceUrl: (state, getters) => config => {
      let model = getters.model
      if (!model.urlParent) return ''

      // Get the referenced parentRelation
      const urlParentRelation = model.relations.find(relation => relation.field === model.urlParent)
      if (!urlParentRelation) {
        throw new Error(`${model.name} has a urlParent '${model.urlParent}' defined, but no belongsTo rel was found.`)
      }

      // Create the url-part correpsonding to this parent
      const parentModel = urlParentRelation.relatedModel
      const keyName = urlParentRelation.foreignKey

      let parent_id =
        (config.params && config.params[keyName]) ||
        (config.instance && config.instance[keyName]) ||
        (config.data && config.data[keyName])
      config.params && delete config.params[keyName] // Clear the parent_id if extracted from the params: it is now in the url path.
      if (!parent_id) {
        throw new Error(`'${model.name}' has a url nested under '${parentModel.name}', but '${keyName}' wasnt passed.`)
      }
      let url = `${parentModel.getters('resourceUrl')}/${parent_id}`

      // Recurse
      if (parentModel.urlParent) {
        // The parent also has a nested url, so get it's instance and recurse:
        let parentInstance = parentModel.find(parent_id)
        if (!parentInstance) {
          throw new Error(`The url of '${model.name}' is nested under '${parentModel.name}',
          but the referenced instance of '${parentModel.name}' with id ${parent_id} was not found in the store.`)
        }
        url = parentModel.getters('nestedResourceUrl')({ instance: parentInstance }) + url
      }
      return url
    },
    url: (state, getters) => config => {
      config.params = { ...config.params } // Make a copy, as each param that is moved into the url will be deleted
      let endpoint = getters.nestedResourceUrl(config) + getters.resourceUrl + config.methodUrl + config.suffix
      endpoint = substituteParams(endpoint, config.params)
      return endpoint
    },
  },
  actions: {
    request(context, requestConfig = {}) {
      const ormAxios = OrmAxios.getInstance()
      const config = Object.assign({}, ormAxios.config, requestConfig)
      config.url = context.getters.url(config)

      // Run onRequest callback, with possible request cancellation:
      let shouldRequest = config.onRequest(config)
      if (!shouldRequest) return Promise.reject(shouldRequest)

      config.sendAt = new Date().getTime()
      return ormAxios.axios
        .request(config)
        .then(data => {
          config.onBeforeSuccess()
          data = config.onSucces(data, config, context)
          if (config.injectResponse) {
            // If a backend doesn't return null-values, find the nulls we sent and add them to the data:
            addNullValues({ sent: config.data, received: data })
            // Memoize changes made since the request was send:
            const changesSinceSend = config.instance && config.instance.$changes({ since: config.sendAt })
            const injectedData = context.dispatch('inject', { data, config })
            config.instance && config.instance.$purgeChanges()
            // Re-apply changes made since the request was send:
            if (changesSinceSend) config.instance.$update(changesSinceSend)
            return injectedData
          } else {
            return data
          }
        })
        .catch(error => {
          config.onBeforeError(error, config, context)
          return config.onError(error, config, context)
        })
    },
    apiAll({ dispatch }, callConfig = {}) {
      return dispatch('request', callConfig)
    },
    apiFind({ getters, dispatch }, callConfig = {}) {
      const methodConfig = {
        methodUrl: `/:${getters.model.primaryKey}`,
      }
      const requestConfig = Object.assign({}, methodConfig, callConfig)
      return dispatch('request', requestConfig)
    },
    apiCreate({ getters, dispatch }, callConfig = {}) {
      const methodConfig = {
        method: 'POST',
      }

      if (callConfig.eagerInject) {
        getters.model.inject(callConfig.data)
      }

      let primaryKey = getters.model.primaryKey
      let id = callConfig.data[primaryKey]
      // Create can also be called without an id
      if (id < 0) {
        let instance = getters.find(id)
        callConfig.instance = instance

        // Remove the negative id from the data to send:
        callConfig.data = { ...callConfig.data } // Clone to prevent modifying the original
        delete callConfig.data[primaryKey]

        methodConfig.onSucces = data => {
          // Update the negative id with the one from the server:
          dispatch('updateId', { instance, newId: data[primaryKey] })
          return data
        }

        methodConfig.onError = error => {
          if (callConfig.eagerInject) dispatch('eject', instance)
          return Promise.reject(error)
        }
      }

      const requestConfig = Object.assign({}, methodConfig, callConfig)
      return dispatch('request', requestConfig)
    },
    apiUpdate({ getters, dispatch }, callConfig = {}) {
      let id = callConfig.params[getters.model.primaryKey]
      if (id < 0) return Promise.reject()
      let instance = getters.find(id)
      callConfig.instance = instance

      const methodConfig = {
        method: 'PUT',
        methodUrl: `/:${getters.model.primaryKey}`,
      }

      const requestConfig = Object.assign({}, methodConfig, callConfig)
      return dispatch('request', requestConfig)
    },
    apiDestroy({ getters, dispatch }, callConfig = {}) {
      let id = callConfig.params[getters.model.primaryKey]
      if (id < 0) return Promise.reject()
      let instance = getters.find(id)
      callConfig.instance = instance

      const methodConfig = {
        method: 'DELETE',
        methodUrl: `/:${getters.model.primaryKey}`,
        injectResponse: false,
        onSucces() {
          if (!callConfig.eagerEject) dispatch('eject', instance)
          return instance
        },
      }

      if (callConfig.eagerEject) {
        dispatch('eject', instance)
        methodConfig.onError = error => {
          dispatch('injectOneNormalized', { instance }) // Use injectOneNormalize to omit Normalizer, which kills any object refs.
          return Promise.reject(error)
        }
      }

      const requestConfig = Object.assign({}, methodConfig, callConfig)
      return dispatch('request', requestConfig)
    },
    apiCall({ getters, dispatch }, payload = {}) {
      const action = payload.hasOwnProperty('action') ? payload.action : payload
      const callConfig = payload.hasOwnProperty('config') ? payload.config : {}

      const methodConfig = {
        injectResponse: false,
      }

      // Search the model for the config of the requested action:
      const actionConfig = (getters.model.apiActions && getters.model.apiActions[action]) || {}
      const requestConfig = Object.assign({}, methodConfig, actionConfig, callConfig)
      return dispatch('request', requestConfig)
    },
  },
}
