// Add functionality to load and register modules on demand, and prevent loading twice.
// Inspired by https://github.com/vuejs/vuex/issues/833#issuecomment-455573835
import Vuex from 'vuex'

Vuex.Store.prototype.registerModuleOnce = function(path, rawModule, opts) {
  if (this.hasModule(path)) return false
  return this.registerModule(path, rawModule, opts)
}

Vuex.Store.prototype.hasModule = function(path) {
  let pathArray = path instanceof Array ? path : path.split('/')
  let m = this._modules.root
  return pathArray.every(p => {
    m = m._children[p]
    return m
  })
}
