import Model from '@/services/orm/Model'
import { nowRoundedToSec } from '@/services/utils'
import store from '@/store'
import router from '@/router'
import i18n from '@/plugins/i18n'
import moment from 'moment'

export default class Ascend extends Model {
  static name = 'ascends'

  static relations = [
    Ascend.belongsTo('users', { key: 'user_id', field: 'user' }),
    Ascend.belongsTo('climbs', { key: 'climb_id', field: 'climb' }),
  ]

  static apiActions = {
    createMany: {
      methodUrl: '/create_many',
      method: 'POST',
    },
    updateMany: {
      methodUrl: '/update_many',
      method: 'PUT',
    },
  }

  static $apiSaveMany(ascends) {
    if (store.state.kiosk.isKiosk) {
      store.dispatch('kiosk/showGetAppDialog')
      return Promise.resolve()
    }

    if (store.state.user.guest) {
      store.dispatch('dialog/confirm', {
        title: i18n.t('climbs.guestMsg'),
        text: i18n.t('auth.loginToSaveProgress'),
        ok: i18n.t('auth.login'),
        onSave: () => router.pushSmart({ name: 'auth.login' }),
      })
      return Promise.resolve()
    }

    let createDfd = Promise.resolve()
    let updateDfd = Promise.resolve()

    let toCreate = ascends.filter(ascend => ascend.$isNew())
    let toUpdate = ascends.filter(ascend => !ascend.$isNew())

    // Create new ones:
    if (toCreate.length) {
      let removeTempAscends = () => {
        toCreate.forEach(ascend => ascend.$eject())
      }
      createDfd = Ascend.$apiCall('createMany', {
        data: {
          ascends: toCreate,
        },
        injectResponse: true,
        onSucces: data => {
          removeTempAscends()
          return data
        },
      }).then(() => {}, removeTempAscends)
    }

    // Update existing ones:
    if (toUpdate.length) {
      let changes = toUpdate.reduce((changes, ascend) => {
        return Object.assign(changes, ascend.$changes())
      }, {})
      if (Object.keys(changes).length) {
        updateDfd = Ascend.$apiCall('updateMany', {
          injectResponse: true,
          data: {
            ids: toUpdate.map(ascend => ascend.id),
            ascend: changes,
          },
        }).then(
          () => toUpdate.forEach(ascend => ascend.$purgeChanges()),
          () => toUpdate.forEach(ascend => ascend.$restore())
        )
      }
    }
    return Promise.all([createDfd, updateDfd])
  }

  static async checkMany(ascends, zone, checks) {
    let oldestAscend = ascends.slice().sort((a, b) => a.date_logged - b.date_logged)[0]
    let requiresConfirmation = moment(oldestAscend.date_logged).isBefore(moment().subtract(1, 'day'))

    if (requiresConfirmation) {
      let confirmed = await store.dispatch('dialog/confirm', {
        title: i18n.t('climbs.log.all.areYouSure'),
        text: i18n.t('climbs.log.all.update', { timeAgo: moment(oldestAscend.date_logged).fromNow() }),
        ok: i18n.t('climbs.log.all.updateAscends'),
      })
      if (!confirmed) return false
    }

    ascends.forEach(ascend => ascend.check(zone, checks))
    return Ascend.$apiSaveMany(ascends)
  }

  static async uncheckMany(ascends) {
    let oldestAscend = ascends.slice().sort((a, b) => a.date_logged - b.date_logged)[0]
    let requiresConfirmation = moment(oldestAscend.date_logged).isBefore(moment().subtract(1, 'day'))

    if (requiresConfirmation) {
      let translateParams = {
        timeAgo: moment(oldestAscend.date_logged).fromNow(),
        routeBoulder: store.state.climbType,
      }

      let confirmed = await store.dispatch('dialog/confirm', {
        title: i18n.t('climbs.log.all.areYouSure'),
        text:
          ascends.length > 1
            ? i18n.t('climbs.log.all.uncheck', translateParams)
            : i18n.t('climbs.log.uncheckMsg', translateParams),
        ok:
          ascends.length > 1 // eslint-disable-line
            ? i18n.t('climbs.log.all.removeAscends')
            : i18n.t('climbs.log.removeAscend'),
      })
      if (!confirmed) return false
    }

    ascends.forEach(ascend => ascend.uncheck())
    return Ascend.$apiSaveMany(ascends)
  }

  get zoneOrInf() {
    return this.topped ? Infinity : this.zone || 0
  }

  check(zone, checks = 1) {
    let topped = zone == Infinity
    let zoneParsed = zone == Infinity ? null : zone

    // Prevent overwriting date_logged if nothing changed
    if (this.checks == checks && this.zone == zoneParsed && this.topped == topped) return

    return this.$update({
      checks: checks,
      topped: topped,
      zone: zoneParsed,
      date_logged: nowRoundedToSec(), // Round to seconds (thats what the server returns) to prevent an extra save.
    })
  }

  uncheck() {
    return this.$update({
      checks: null,
      zone: null,
      topped: false,
      date_logged: null,
      top_type: null, // This can be removed when fully switched to vue.
      used: false, // This can be removed when fully switched to vue.
    })
  }
}
