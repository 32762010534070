import Model from '@/services/orm/Model'
import { parseDateSafe, parseFloatSafe } from '@/services/parsers'

export default class Opinion extends Model {
  static name = 'opinions'

  static relations = [
    Opinion.belongsTo('users', { key: 'user_id', field: 'user' }),
    Opinion.belongsTo('climbs', { key: 'climb_id', field: 'climb' }),
  ]

  static parsers = {
    date_reported: parseDateSafe,
    date_report_removed: parseDateSafe,
    grade: parseFloatSafe,
  }
}
