export default {
  classProps: {
    apiConfig: {},
    $apiAll(config = {}) {
      return this.$apiDispatch('apiAll', config)
    },
    $apiFind(id, config = {}) {
      config.params = config.params || {}
      config.params.id = id
      return this.$apiDispatch('apiFind', config)
    },
    $apiCreate(attrs, config = {}) {
      config.data = attrs
      return this.$apiDispatch('apiCreate', config)
    },
    $apiCall(action, callConfig = {}) {
      let config = Object.assign({}, this.apiConfig, callConfig)
      return this.dispatch('apiCall', { action, config })
    },
    $apiDispatch(dispatchName, callConfig) {
      let config = Object.assign({}, this.apiConfig, callConfig)
      return this.dispatch(dispatchName, config)
    },
  },
  instanceProps: {
    $apiCreate(config = {}) {
      config.data = JSON.parse(JSON.stringify(this)) // Clones and removes relation fields by calling Model.toJSON
      return this.constructor.$apiDispatch('apiCreate', config) // TODO: Checken of dit werkt met een non-injected instance.
    },
    $apiUpdate(changes, config = {}) {
      if (!changes || !Object.keys(changes).length) return Promise.resolve()
      config.data = changes
      config.data.id = this.$id
      config.params = config.params || {}
      config.params.id = this.$id
      this.constructor.relations.forEach(r => delete config.data[r.field]) // Prevent saving relation fields
      return this.constructor.$apiDispatch('apiUpdate', config)
    },
    $apiSave(config = {}) {
      return this.$isNew() ? this.$apiCreate(config) : this.$apiUpdate(this.$changes(), config)
    },
    $apiDestroy(config = {}) {
      config.params = config.params || {}
      config.params.id = this.$id
      return this.constructor.$apiDispatch('apiDestroy', config)
    },
    $apiCall(action, callConfig = {}) {
      let config = Object.assign({}, this.apiConfig, callConfig)
      config.params = config.params || {}
      config.params.id = this.$id
      return this.constructor.dispatch('apiCall', { action, config })
    },
  },
}
