import Model from '@/services/orm/Model'
import { parseDateSafe } from '@/services/parsers'

export default class Region extends Model {
  static name = 'regions'
  static urlParent = 'gym'

  static relations = [
    Region.belongsTo('gyms', { key: 'gym_id', field: 'gym' }),
    Region.hasMany('region_walls', { key: 'region_id' }),
  ]

  static parsers = {
    // id: this.increment(),
    // gym_id: this.attr(null),
    // name: this.string(''),
    // order: this.number('').nullable(),
    // climbs_type: this.string('boulders'),
    // deleted: this.boolean(false),
    deleted_at: parseDateSafe,
    created_at: parseDateSafe,
    // desired_grades: this.attr(null),
    // grade_counts: this.attr(null),
  }
}
