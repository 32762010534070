import Model from '@/services/orm/Model'
import { parseDateSafe } from '@/services/parsers'

export default class Comp extends Model {
  static name = 'comps'
  static endpoint = 'groups'

  static relations = [
    // Temp fields until the backend has been rewritten and models named properly
    Comp.hasMany('comp_gyms', { key: 'group_id', field: 'gym_groups' }),
    Comp.hasMany('comp_climbs', { key: 'group_id', field: 'climb_groups' }),
    Comp.hasMany('comp_users', { key: 'group_id', field: 'group_users' }),
    Comp.hasMany('poules', { key: 'group_id' }),
  ]

  // Temp getters until the backend has been rewritten and models named properly
  get comp_gyms() {
    return this.gym_groups
  }
  get comp_climbs() {
    return this.climb_groups
  }
  get comp_users() {
    return this.group_users
  }

  toggleLive(newVal) {
    let newVals = {}
    newVals.live = typeof newVal !== 'undefined' ? !!newVal : !this.live
    if (newVals.live) {
      newVals.lived = true
      newVals.date_live_start = this.date_live_start || new Date().toISOString()
      newVals.date_live_end = null
    } else if (this.lived) {
      newVals.date_live_end = new Date().toISOString()
    }
    this.$update(newVals)
    return this.$apiSave()
  }

  get expired() {
    return parseDateSafe(this.date_loggable_end) < new Date()
  }
  get started() {
    return parseDateSafe(this.date_loggable_start) < new Date()
  }
  get isSingleDay() {
    return (
      this.date_loggable_start &&
      this.date_loggable_end &&
      new Date(this.date_loggable_start).toDateString() === new Date(this.date_loggable_end).toDateString()
    )
  }
}
