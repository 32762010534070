const state = () => ({
  selection: [],
  selecting: [],
  multiSelectMode: false,
  ignoreNextClick: false,
  methodSelect: null,
  methodDeselect: null,
})

const getters = {
  selected(state) {
    return item => state.selection.includes(item)
  },
  selecting(state) {
    return item => state.selecting.includes(item)
  },
}

const mutations = {
  deselect(state, item) {
    let index = state.selection.indexOf(item)
    if (index === -1) return
    state.selection.splice(index, 1)
    if (!state.selection.length) {
      state.multiSelectMode = false
    }
  },
  select(state, item) {
    state.selection.push(item)
  },
  setSelection(state, newSelection) {
    state.selection = newSelection
  },
  setMultiSelectMode(state, newVal) {
    state.multiSelectMode = !!newVal
  },
  setIgnoreNextClick(state, newVal) {
    state.ignoreNextClick = !!newVal
  },
  addSelecting(state, item) {
    state.selecting.push(item)
  },
  removeSelecting(state, item) {
    let index = state.selecting.indexOf(item)
    if (index === -1) return
    state.selecting.splice(index, 1)
  },
  setMethodSelect(state, method) {
    state.methodSelect = method
  },
  setMethodDeselect(state, method) {
    state.methodDeselect = method
  },
}

const actions = {
  async select({ state, commit }, item) {
    if (state.selection.includes(item)) return
    if (state.selecting.includes(item)) return
    if (state.methodSelect) {
      commit('addSelecting', item)
      await state.methodSelect(item).then(() => commit('select', item))
      commit('removeSelecting', item)
    } else {
      commit('select', item)
    }
  },
  async deselect({ state, commit }, item) {
    if (!state.selection.includes(item)) return
    if (state.selecting.includes(item)) return
    if (state.methodDeselect) {
      commit('addSelecting', item)
      await state.methodDeselect(item).then(() => commit('deselect', item))
      commit('removeSelecting', item)
    } else {
      commit('deselect', item)
    }
  },
  setSelection({ state, commit, dispatch }, newSel) {
    if (!state.methodSelect && !state.methodDeselect) {
      commit('setSelection', newSel) // Take the quick shortcut if possible
    }
    let oldSel = state.selection
    let toSelect = newSel.filter(item => !oldSel.includes(item))
    let toDeselect = oldSel.filter(item => !newSel.includes(item))
    toSelect.forEach(item => dispatch('select', item))
    toDeselect.forEach(item => dispatch('deselect', item))
  },
  clear({ commit, dispatch }) {
    dispatch('setSelection', [])
    commit('setMultiSelectMode', false)
  },
  toggleSelect({ getters, dispatch }, item, value) {
    let method = getters.selected(item) ? 'deselect' : 'select'
    if (value != null) method = value ? 'select' : 'deselect'
    dispatch(method, item)
  },
  handleClick({ state, getters, commit, dispatch }, { event, item }) {
    if (state.ignoreNextClick) return commit('setIgnoreNextClick', false)
    if (state.multiSelectMode || event.ctrlKey || event.metaKey) {
      dispatch('toggleSelect', item)
    } else {
      const newSelection = getters.selected(item) ? [] : [item]
      dispatch('setSelection', newSelection)
    }
  },
  handleLongPress({ state, commit, dispatch }, item) {
    if (!state.multiSelectMode) commit('setMultiSelectMode', true)
    dispatch('toggleSelect', item)
    commit('setIgnoreNextClick', true)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
