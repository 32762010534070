import Model from '@/services/orm/Model'

export default class CompGym extends Model {
  static name = 'comp_gyms'
  static endpoint = 'gym_groups'
  static urlParent = 'gym'

  static relations = [
    CompGym.belongsTo('comps', { key: 'group_id', field: 'group' }),
    CompGym.belongsTo('gyms', { key: 'gym_id', field: 'gym' }),
  ]

  // Temp getter until the backend has been rewritten and models named properly
  get comp() {
    return this.group
  }
}
