import Vue from 'vue'
import Vuex from 'vuex'
import User from '@/models/User'
import Gym from '@/models/Gym'
import Grade from '@/services/grades/Grade'
import i18n from '@/plugins/i18n'
import i18nStore from '@/services/i18n/i18n.store'
import authStore from '@/components/auth/auth.store'
import kioskStore from '@/services/kiosk.store'
import cordovaStore from '@/services/cordova/cordova.store'
import selectionStore from '@/services/selection.store'
import dialogStore from '@/components/layout/dialog/dialog.store'
import navStore from '@/components/layout/nav/nav.store.js'
import navRightStore from '@/components/layout/nav-right/nav-right.store.js'
import toastStore from '@/components/layout/toast/toast.store'
import toolbarStore from '@/components/layout/toolbar/toolbar.store.js'
import errorService from '@/services/error-service'

Vue.use(Vuex)

const state = {
  user: null,
  userViewed: null,
  userViewedLoading: false,
  gym: null,
  climbType: 'boulder',
  isAdmin: false,
}

const getters = {
  userViewed(state) {
    return state.userViewedLoading ? null : state.userViewed || state.user
  },
}

const mutations = {
  setUser(state, user) {
    state.user = user
  },
  setUserViewed(state, user) {
    state.userViewed = user
  },
  setUserViewedLoading(state, loading) {
    state.userViewedLoading = !!loading
  },
  setGym(state, gym) {
    if (!gym || gym === state.gym) return

    // Save it on the user, so it will be eager loaded on the user:
    state.user.$update({ gym_id: gym.id })
    state.user.$apiSave().catch(() => {}) // No await here, as this shouldn't block page rendering.

    // Persist gymSlug to localStorage:
    state.user.setStorageStateProp('gymSlug', gym.slug)
    state.gym = gym
  },
  setClimbType(state, newType) {
    if (!['boulder', 'route'].includes(newType)) return
    state.climbType = newType
    state.user.setStorageStateProp('climbType', newType)
  },
  setIsAdmin(state, newValIsAdmin) {
    state.isAdmin = !!newValIsAdmin
  },
}

const actions = {
  async assureUser({ state, getters, commit, dispatch }) {
    if (getters['auth/isAuth']) {
      if (state.user && state.user.id == state.auth.userId) return state.user

      await User.$apiFind(state.auth.userId, {
        params: { json_params: { includes: [{ gym: ['holds', 'walls', 'setters'] }, 'setters'] } },
        timeout: 0,
      })
        .then(user => commit('setUser', user))
        .catch(async e => {
          errorService.toast(e)
          await dispatch('assureGuest')
        })
    } else {
      await dispatch('assureGuest')
    }
    return state.user
  },
  async assureGuest({ state, commit }) {
    if (!state.user || !state.user.guest) {
      let user = await User.findOrCreateGuest()
      commit('setUser', user)
    }
  },
  async setGym({ state, getters, commit, dispatch }, slug) {
    if (state.gym && state.gym.slug === slug) return state.gym

    if (state.kiosk.isKiosk) {
      if (getters['kiosk/isKioskLocked']) {
        // Prevent switching gyms in locked kiosk:
        if (slug != state.kiosk.kioskGymSlug) {
          dispatch('toast/error', `${i18n.t('gyms.notAllowed')} ${i18n.t('gyms.noSwitching')}`)
          return
        }
      } else {
        return // Block gym pages for non-locked kiosk.
      }
    }

    // Find the gym locally with fetch remote as fallback:
    let gym = Gym.findBy('slug', slug)
    if (!gym) {
      gym = await Gym.$apiCall('findBySlug', {
        params: { slug, json_params: { includes: ['holds', 'walls', 'setters'] } },
        timeout: 0,
      })
    }

    // Couldn't find gym with this slug
    if (!gym) return

    commit('setGym', gym)
    dispatch('updateGradeSystems')
    return gym
  },
  setIsAdmin({ commit, dispatch }, isAdmin) {
    commit('setIsAdmin', isAdmin)
    dispatch('updateGradeSystems')
  },
  updateGradeSystems({ state }) {
    Grade.updateCustomSystems(state.gym)
    let systemRoutes = state.gym[`grading_system_routes`]
    let systemBoulders = state.gym[`grading_system_boulders`]
    if (!state.isAdmin) {
      if (state.user.grading_system_routes) systemRoutes = state.user.grading_system_routes
      if (state.user.grading_system_boulders) systemBoulders = state.user.grading_system_boulders
    }
    Grade.setSystem('route', systemRoutes)
    Grade.setSystem('boulder', systemBoulders)
  },
}

export default new Vuex.Store({
  modules: {
    i18n: i18nStore,
    auth: authStore,
    kiosk: kioskStore,
    cordova: cordovaStore,
    selection: selectionStore,
    dialog: dialogStore,
    nav: navStore,
    'nav-right': navRightStore,
    toast: toastStore,
    toolbar: toolbarStore,
  },
  state,
  getters,
  mutations,
  actions,
})
