import Vue from 'vue'
import i18n from '@/plugins/i18n'

let promiseResolve, promiseReject // Keep outside the state so each open returns a fresh promise.

const state = () => ({
  component: null,
  componentProps: null,
  visible: false,
  fullscreen: false,
  maxWidth: 290,
})

const mutations = {
  setComponent(state, { component, props }) {
    state.component = component
    state.componentProps = props
    state.maxWidth = props.maxWidth || 290
    state.fullscreen = !!props.fullscreen
  },
  clearComponent(state) {
    state.component = null
    state.componentProps = null
    state.maxWidth = 290
    state.fullscreen = false
  },
  show(state) {
    state.visible = true
  },
  hide(state) {
    state.visible = false
  },
}

const actions = {
  open({ commit }, payload) {
    commit('setComponent', payload)
    Vue.nextTick(() => commit('show'))
    return new Promise((resolve, reject) => {
      promiseResolve = resolve
      promiseReject = reject
    }).catch(() => {})
  },
  close({ commit }, result) {
    commit('hide')
    Vue.nextTick(() => commit('clearComponent'))
    if (!promiseResolve || !promiseReject) return
    result != undefined ? promiseResolve(result) : promiseReject('Dialog closed')
  },
  alert({ dispatch }, props = {}) {
    props.ok = props.ok || i18n.t('generic.gotIt')
    return dispatch('openPrompt', props)
  },
  confirm({ dispatch }, props = {}) {
    props.cancel = props.cancel || i18n.t('generic.cancel')
    props.ok = props.ok || i18n.t('generic.yes')
    return dispatch('openPrompt', props)
  },
  prompt({ dispatch }, props = {}) {
    props.prompt = true
    props.cancel = props.cancel || i18n.t('generic.cancel')
    props.ok = props.ok || i18n.t('generic.ok')
    return dispatch('openPrompt', props)
  },
  openPrompt({ dispatch }, props) {
    const component = () => import('./tl-prompt')
    return dispatch('open', { component, props })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
